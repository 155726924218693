export type PiecesProps = {
  stageConfig: StageConfig;
  onPieceClick: (piece: PieceConfig) => void;
};

import { useItems } from '../item/api/getItems';

import Piece from './Piece';
import { PieceConfig, StageConfig } from './types';

const Pieces = ({ stageConfig, onPieceClick }: PiecesProps) => {
  const { data: items } = useItems({ stageId: stageConfig.id });

  return (
    <>
      {items &&
        stageConfig.pieces.map((x) => (
          <Piece
            key={x.stageItemId}
            piece={x}
            isActive={
              items.data.find((item) => item.stageItem.id == x.stageItemId)?.confirmed ?? false
            }
            onClick={onPieceClick}
          />
        ))}
    </>
  );
};

export default Pieces;
