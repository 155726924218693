// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Response } from '@/types';

import { UserStage } from '../types';

export const getUserStage = ({ stageId }: { stageId: number }): Promise<Response<UserStage>> => {
  return axios.get('game/user_stage', {
    params: {
      stageId: stageId,
    },
  });
};

type GetUserStageQueryFnType = typeof getUserStage;

type GetUserStageOptions = {
  stageId: number;
  config?: QueryConfig<GetUserStageQueryFnType>;
};

export const useUserStage = ({ config, stageId }: GetUserStageOptions) => {
  return useQuery<ExtractFnReturnType<GetUserStageQueryFnType>>({
    queryKey: ['userStage', stageId],
    queryFn: () => getUserStage({ stageId }),
    ...config,
  });
};
