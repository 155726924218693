import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';
import Ribbon from '@/components/Elements/Ribbon';

import MyBadges from './MyBadges';
import { Badge } from './types';

type MyBadgePopupProps = {
  badges: Badge[];
  openDialog: boolean;
  onClose: () => void;
};

const MyBadgesPopup = ({ badges, openDialog, onClose }: MyBadgePopupProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Dialog openDialog={openDialog} borderColor="blueLight" backgroundColor="gradientSkyBlue">
        <Ribbon type="leftBlue" title={t('badge.my_badge_popup.title')} />
        <div className="flex justify-center items-center h-full bg-blackGray rounded-[1.25rem] mx-[3.125rem] py-1 smLarge:py-0">
          <p className="fsz-20ptr text-white font-bold">
            {t('badge.my_badge_popup.count', {
              count: badges.length,
            })}
          </p>
        </div>
        <div className="mt-5 flex flex-col smLarge:max-h-[18.75rem] overflow-y-auto scrollbar scrollbar-very-thin scrollbar-thumb-rounded scrollbar-track-rounded scrollbar-track-mintGreen scrollbar-thumb-skyBlue">
          <div className="mx-2 grid grid-cols-3 gap-4 md:max-h-[18.375rem] smLarge:max-h-[13rem] justify-items-center">
            <MyBadges badges={badges} />
          </div>
        </div>
        <Button
          className="md:mt-10 md:mx-[3.75rem] mx-[1.25rem] mt-5"
          text={t('common.close')}
          colorType={'orange'}
          buttonSize={'large'}
          onClick={onClose}
        />
      </Dialog>
    </>
  );
};

export default MyBadgesPopup;
