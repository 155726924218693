import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { Response } from '@/types';

import { Stage } from '../types';

export type SelectStageDTO = {
  stageId: number;
};

export const selectStage = (req: SelectStageDTO): Promise<Response<Stage>> => {
  return axios.patch(`game/stages/select_stage`, req);
};

type UseSelectStageOptions = {
  config?: MutationConfig<typeof selectStage>;
};

export const useSelectStage = ({ config }: UseSelectStageOptions = {}) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['activeStage']);
    },
    ...config,
    mutationFn: selectStage,
  });
};
