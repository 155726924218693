import { AnimatePresence, motion } from 'framer-motion';

import { useLoadingStore } from '@/stores/loading';

type LoaderProps = {
  children: React.ReactNode;
};

const Loader = ({ children }: LoaderProps) => {
  const { processes, addProcess: register, removeProcess: unregister } = useLoadingStore();
  const loadingProcessId = 'animation';

  return (
    <AnimatePresence>
      {processes.size > 0 && (
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed z-1000004 w-screen h-screen bg-gradient-to-b from-loaderTop to-loaderBottom"
        >
          {children}
          <div className="absolute h-full w-full">
            <img className="absolute top-[5%] -right-[2%]" src="./loader/cloud_b.svg" alt="" />
            <img className="absolute top-[40%] left-[2%]" src="./loader/cloud_a.svg" alt="" />
            <img className="absolute top-[70%] left-[15%]" src="./loader/cloud_b.svg" alt="" />
          </div>
          <motion.div
            className="absolute w-full"
            initial={{ top: '50%', left: '60%' }}
            animate={{ top: '45%', left: '-30%' }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
            }}
            onAnimationStart={() => register(loadingProcessId)}
            onAnimationComplete={() => unregister(loadingProcessId)}
          >
            <img className="w-96" src="./sugoroku/character_moving.svg" alt=""></img>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Loader;
