// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Task, StageItem } from '../types';

const getTaskAndItem = async ({
  stageItemId,
}: {
  stageItemId: number;
}): Promise<{ task: Task; stageItem: StageItem }> => {
  const stageItem = await axios.get(`/game/stage_items/${stageItemId}`);
  const task = await axios.get(`/game/tasks/${stageItem.data.taskId}`);
  return {
    stageItem: stageItem.data,
    task: task.data,
  };
};

type GetTaskQueryFnType = typeof getTaskAndItem;

type GetTaskOptions = {
  stageItemId: number;
  config?: QueryConfig<GetTaskQueryFnType>;
};

export const useTaskAndItem = ({ stageItemId, config }: GetTaskOptions) => {
  return useQuery<ExtractFnReturnType<GetTaskQueryFnType>>({
    queryKey: ['stageItemId', stageItemId],
    queryFn: () => getTaskAndItem({ stageItemId }),
    ...config,
    onError: () => {
      console.log('error caught');
    },
  });
};
