import create from 'zustand';

export type OnboardingStep =
  | 'gameOnBoarding'
  | 'howToPlay'
  | 'itemAcquisition'
  | 'boardAnimation'
  | 'getPiece'
  | 'goalIntroduction'
  | 'tipsHighlight'
  | 'tips'
  | 'completed';

const onboardingSteps: OnboardingStep[] = [
  'gameOnBoarding',
  'howToPlay',
  'itemAcquisition',
  'boardAnimation',
  'getPiece',
  'goalIntroduction',
  'tipsHighlight',
  'tips',
  'completed',
];

export type OnboardingStatus = {
  currentStep?: OnboardingStep;
  open: () => void;
  proceed: () => void;
};

export const useOnboardingStatusStore = create<OnboardingStatus>((set) => ({
  currentStep: undefined,
  onboardingItem: undefined,
  open: () => set(() => ({ currentStep: 'gameOnBoarding' })),
  proceed: () =>
    set((state) => ({
      currentStep: onboardingSteps.at(onboardingSteps.indexOf(state.currentStep!) + 1),
    })),
}));
