import { useEffect, useRef, useState } from 'react';

import LockScreen from '@/components/Elements/LockScreen';
import { SugorokuStageConfig } from '@/config';
import smoothScroll from '@/utils/smoothScroll';

import { useItemsMultipleStage } from '../item/api/getItems';
import useItemConfirmationStore from '../item/stores/ItemConfirmationStore';
import { Item } from '../item/types';
import { useOnboardingStatusStore } from '../onboarding/stores/OnboardingStatusStore';

import Character from './Character';
import MapImages from './MapImages';
import Pieces from './Pieces';
import TipsHighlight from './TipsHighlight';
import { ICharacterAnimator, IUnlockEffectAnimator, StageConfig, PieceConfig } from './types';
import UnlockEffect from './UnlockEffect';

export type BoardProps = {
  stageConfig: StageConfig;
  userItems: Item[];
  onPieceClick: (piece: PieceConfig) => void;
};

const Board = ({ stageConfig: activeStage, userItems, onPieceClick }: BoardProps) => {
  const scrollableRef = useRef<HTMLDivElement>(null);
  const imageSize = { height: 8000, width: 14000 };
  const characterRef = useRef<ICharacterAnimator>(null);
  const effectRef = useRef<IUnlockEffectAnimator>(null);
  const [currentPiece, setCurrentPiece] = useState<PieceConfig>();
  const [lock, setLock] = useState(false);
  const [firstScrolled, setFirstScrolled] = useState(false);
  const previousStageItems = useItemsMultipleStage(
    SugorokuStageConfig()
      .filter((x) => x.order < activeStage.order)
      .map((x) => x.id)
  );

  const { currentStep: onboardingStep, proceed: proceedOnboarding } = useOnboardingStatusStore();
  const {
    item: confirmingItem,
    currentStep: itemConfirmationStep,
    proceed: proceedItemConfirmation,
  } = useItemConfirmationStore();

  const scrollToPiece = async (item: PieceConfig, duration: number) => {
    if (!scrollableRef.current) return;
    const offsetY = -70;
    return smoothScroll(
      scrollableRef.current,
      {
        left: item.left - (window.innerWidth ?? 0) * 0.5,
        top: item.top - (window.innerHeight ?? 0) * 0.5 + offsetY,
      },
      { duration: duration }
    );
  };

  const animateProceed = async (nextPiece: PieceConfig) => {
    if (!scrollableRef.current) return;
    await new Promise((resolve) => setTimeout(resolve, 200));
    await scrollToPiece(nextPiece, 1);
    await effectRef.current?.animate(nextPiece.top, nextPiece.left, nextPiece);
    await characterRef.current?.animate(nextPiece.top, nextPiece.left);
  };

  useEffect(() => {
    if (onboardingStep == 'boardAnimation')
      (async () => {
        setLock(true);
        await animateProceed(activeStage.pieces[0]);
        setLock(false);
        proceedOnboarding();
      })();
  }, [onboardingStep]);

  useEffect(() => {
    if (!previousStageItems.every((x) => x.data)) return;
    const latestItem =
      userItems
        .filter((x) => x.confirmed)
        .sort((a, b) => a.confirmedAt.getTime() - b.confirmedAt.getTime())
        .at(-1) ??
      previousStageItems
        .flatMap((x) => x.data!.data)
        .filter((x) => x.confirmed)
        .sort((a, b) => a.confirmedAt.getTime() - b.confirmedAt.getTime())
        .at(-1);
    setCurrentPiece(
      SugorokuStageConfig()
        .flatMap((x) => x.pieces)
        .find((x) => x.stageItemId == latestItem?.stageItem.id)
    );
  }, [userItems, previousStageItems]);

  useEffect(() => {
    if (itemConfirmationStep == 'animate' && confirmingItem) {
      (async () => {
        const nextPiece = activeStage.pieces.find(
          (x) => x.stageItemId == confirmingItem.stageItem.id
        );
        if (nextPiece) await animateProceed(nextPiece);
        proceedItemConfirmation();
      })();
    }
  }, [itemConfirmationStep]);

  useEffect(() => {
    if (!firstScrolled && currentPiece) {
      setFirstScrolled(true);
      scrollToPiece(currentPiece, 0);
    }
  }, [currentPiece]);

  return (
    <>
      <div
        className="flex h-full items-stretch overflow-scroll overscroll-none relative"
        ref={scrollableRef}
      >
        <div className="relative">
          <MapImages stageKey={activeStage.key} />
          {SugorokuStageConfig().map((stage) =>
            stage.order <= activeStage.order ? (
              <Pieces onPieceClick={onPieceClick} key={stage.id} stageConfig={stage} />
            ) : (
              <div key={stage.id}></div>
            )
          )}
        </div>
        <TipsHighlight
          backgroundHeight={imageSize?.height ?? 0}
          backgroundWidth={imageSize?.width ?? 0}
          stageConfig={activeStage}
        />
        <UnlockEffect ref={effectRef} />
        <LockScreen open={lock} />
        <Character
          ref={characterRef}
          initialPosition={{
            top: currentPiece?.top ?? 0,
            left: currentPiece?.left ?? imageSize?.width ?? 0,
          }}
        />
      </div>
    </>
  );
};

export default Board;
