// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { t } from 'i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';

import { useConsumeSkipItem } from './api/consumeSkipItem';
import { SkipItem } from './types';

export type ConsumeSkipItemProps = {
  openDialog: boolean;
  stageItemId: number;
  skipItems: SkipItem[];
  onClose: () => void;
};

const ConfirmConsumeSkipItem = ({
  openDialog,
  stageItemId,
  skipItems,
  onClose,
}: ConsumeSkipItemProps) => {
  const consumeMutation = useConsumeSkipItem();
  const availableSkipItems = skipItems.filter((x) => !x.used);

  return (
    <Dialog openDialog={openDialog} borderColor="blueLight" backgroundColor="gradientSkyBlue">
      <p className="text-center fsz-24ptr whitespace-pre-wrap">
        {t('skip_item.consume_skip_item.do_you_use_ticket')}
      </p>

      <img className="w-36 mx-auto mt-4" src="/skip_item_icon.png" alt="ticket" />

      <p className="text-center tracking-wide mt-2">
        {t('skip_item.my_skip_item_popup.remain')}
        <span className="font-bold fsz-40ptr text-paleBlue">{availableSkipItems.length}</span>
        {t('skip_item.my_skip_item_popup.can_be_used')}
      </p>
      {availableSkipItems[0] && (
        <Button
          className="mt-4 mx-auto"
          text={t('skip_item.consume_skip_item.use')}
          colorType="orange"
          buttonSize="large"
          onClick={async () => {
            await consumeMutation.mutateAsync({
              stageItemId: stageItemId,
              skipItemId: availableSkipItems[0].id,
            });
            onClose();
          }}
        />
      )}

      <Button
        text={t('common.close')}
        onClick={onClose}
        colorType="pink"
        buttonSize="large"
        className="mt-5 md:mt-7 w-16 mx-auto"
      />
    </Dialog>
  );
};

export default ConfirmConsumeSkipItem;
