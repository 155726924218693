// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useQueries, useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Response } from '@/types';

import { Item } from '../types';

export const getItems = ({ stageId }: { stageId: number }): Promise<Response<Item[]>> => {
  return axios.get('game/items', {
    params: {
      stageId: stageId,
    },
  });
};

type GetItemsQueryFnType = typeof getItems;

type GetItemsOptions = {
  stageId: number;
  config?: QueryConfig<GetItemsQueryFnType>;
};

export const useItems = ({ stageId, config }: GetItemsOptions) => {
  return useQuery<ExtractFnReturnType<GetItemsQueryFnType>>({
    queryKey: ['items', stageId],
    queryFn: () => getItems({ stageId }),
    ...config,
  });
};

export const useItemsMultipleStage = (stageIds: number[]) => {
  return useQueries(
    stageIds.map((stageId) => {
      return {
        queryKey: ['items', stageId],
        queryFn: () => getItems({ stageId }),
      };
    })
  );
};
