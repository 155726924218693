import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SugorokuStageConfig } from '@/config';

import GameOnboardingPopup from '../aliance/GameOnboardingPopup';
import { addEvent } from '../event/api/addEvent';
import { useCreateOnboardingItem } from '../item/api/createOnboardingItem';
import GetPiecePopup from '../item/GetPiecePopup';
import ItemAcquisitionPopup from '../item/ItemAcquisitionPopup';
import ItemTipsPopup from '../item/ItemTipsPopup';
import { Item } from '../item/types';
import GoalIntroductionPopup from '../stage/GoalIntroductionPopup';
import { useSkin } from '../sugoroku/hooks';

import { useCompleteOnboarding } from './api/completeOnboarding';
import HowToPlayPopup from './HowToPlayPopup';
import { useOnboardingStatusStore } from './stores/OnboardingStatusStore';

type OnboardingProps = {
  items: Item[];
};

const Onboarding = ({ items }: OnboardingProps) => {
  const completeOnBoarding = useCompleteOnboarding();
  const firstStage = SugorokuStageConfig()[0];
  const firstPiece = firstStage.pieces[0];
  const createItem = useCreateOnboardingItem({ stageId: firstStage.id });
  const { open, currentStep, proceed } = useOnboardingStatusStore();
  const skin = useSkin();
  const { t } = useTranslation([`pieces/${skin}`]);
  const [onboardingItem, setOnboardingItem] = useState<Item>();

  useEffect(() => {
    open();
  }, []);

  useEffect(() => {
    setOnboardingItem(items.find((x) => x.stageItem.id == firstStage.pieces[0].stageItemId));
  }, [items]);

  useEffect(() => {
    switch (currentStep) {
      case 'getPiece':
        if (!onboardingItem) createItem.mutateAsync({ stageItemId: firstPiece.stageItemId });
        break;
      case 'completed':
        completeOnBoarding.mutate({ onboardingCompleted: true });
        break;
    }
  }, [currentStep]);

  switch (currentStep) {
    case 'gameOnBoarding':
      return (
        <GameOnboardingPopup
          onClose={() => {
            addEvent({ eventName: 'start_game' });
            proceed();
          }}
        />
      );
    case 'howToPlay':
      return (
        <HowToPlayPopup
          open={true}
          onClose={() => {
            addEvent({ eventName: 'read_howtoplay' });
            proceed();
          }}
        />
      );
    case 'itemAcquisition':
      return (
        <ItemAcquisitionPopup
          title={t(`${firstPiece.index}.city`, { ns: `pieces/${skin}` })}
          onClose={() => {
            addEvent({ eventName: 'get_item_at_first' });
            proceed();
          }}
        />
      );
    case 'getPiece':
      return (
        <GetPiecePopup
          pieceConfig={firstPiece}
          onClose={() => {
            addEvent({ eventName: 'move_square_at_first' });
            proceed();
          }}
        />
      );
    case 'goalIntroduction':
      return (
        <GoalIntroductionPopup
          stageConfig={firstStage}
          onClose={() => {
            addEvent({ eventName: 'read_howtogoal' });
            proceed();
          }}
        />
      );
    case 'tips':
      return (
        <>
          {onboardingItem && (
            <ItemTipsPopup
              open={true}
              stageItem={onboardingItem.stageItem}
              onClose={() => {
                addEvent({ eventName: 'complete_onboarding' });
                proceed();
              }}
            />
          )}
        </>
      );
    default:
      return <></>;
  }
};

export default Onboarding;
