import moment from 'moment';
import 'moment/locale/ja';

function format(value, format, lng) {
  if (format.startsWith('date')) {
    const formatDateText = format.split('__');
    return formatDateText[1] === undefined
      ? formatDate(value, "LL", lng)
      : formatDate(value, formatDateText[1], lng);
  }
  return value;
}
function formatDate(value, format, lng) {
  if (lng === 'jp') {
    return moment(value).format(format);
  }

  return moment(value).locale('en').format(format);
}
export default format;
