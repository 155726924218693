import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Balloon from '@/components/Elements/Balloon';

import { useSkin } from './hooks';
import { PieceConfig } from './types';

type ClearEffectProps = {
  goalPiece: PieceConfig;
  onClose: () => void;
  isFinalStage: boolean;
};

export const ClearEffect = ({ goalPiece, isFinalStage, onClose }: ClearEffectProps) => {
  const skin = useSkin();
  const { t } = useTranslation(['common', `pieces/${skin}`]);
  const [open, setOpen] = useState(true);

  return (
    <AnimatePresence onExitComplete={onClose}>
      {open && (
        <motion.div
          className="w-full h-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setOpen(false)}
        >
          {isFinalStage ? (
            <div className="flex flex-col items-center h-full w-full">
              <img className="m-auto max-h-full" src="./final_goal.png" alt=""></img>
            </div>
          ) : (
            <>
              <div className="absolute flex flex-col items-center w-full px-5 py-20 lg:gap-8">
                <img className="lg:h-[20rem]" alt="" src="./sugoroku/goal_emblem.png" />
                <p className="text-shadow text-white font-bold text-center sm:fsz-48ptr md:fsz-68ptr lg:fsz-72ptr">
                  {t(`${goalPiece.index}.city`, { ns: `pieces/${skin}` })}
                </p>
              </div>
              <div className="absolute bottom-0 flex items-center flex-col w-full">
                <Balloon
                  style={{
                    translate: '-20% -10%',
                  }}
                >
                  <p className="p-4 lg:p-8 flex fsz-18ptr lg:fsz-24ptr">
                    {t('sugoroku.clear_effect.dialog')}
                  </p>
                </Balloon>
                <img
                  src="./sugoroku/character_banzai.svg"
                  alt=""
                  className="md:h-[17rem] smLarge:h-[12rem] lg:h-[20rem]"
                ></img>
              </div>
            </>
          )}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ClearEffect;
