type RibbonProps = {
  title: string;
  type: Type;
  bookmarkText?: () => string;
};

export type Type = 'leftBlue' | 'centerPurple' | 'centerBlue';

const Ribbon = ({ title, type }: RibbonProps) => {
  // const bookmark = (text: () => string) => (
  //   <div
  //     className="absolute w-[4.25rem] h-[5rem] -top-[4rem] right-[10%]"
  //     style={{ transform: 'rotate(5deg)' }}
  //   >
  //     <img className="absolute" src="/title_ribbon/bookmark.svg" alt="" />
  //     <p className="absolute w-full bottom-4 text-center fsz-16ptr text-white">{text()}</p>
  //   </div>
  // );

  switch (type) {
    case 'leftBlue':
      return (
        <>
          <div className="absolute md:-top-[2rem] md:-left-4 -top-7 -left-[1.1rem] max-w-[375px]">
            <img src="/title_ribbon/left_blue.svg" className="w-[100%] h-auto" alt="itemImage" />
          </div>
          <div className="absolute -top-[0.9rem] -left-3 md:-top-[1rem] md:left-0 w-[190px] h-[30px]">
            <p className="text-white flex justify-center items-center h-full font-bold fsz-24ptr">
              {title}
            </p>
          </div>
        </>
      );
    case 'centerPurple':
    case 'centerBlue':
      return (
        <div className="flex justify-center -translate-y-16">
          {/* {bookmarkText && bookmark(bookmarkText)} */}
          <img
            className="absolute min-w-[21.438rem]"
            alt=""
            src={`/title_ribbon/${type == 'centerBlue' ? 'blue' : 'purple'}.svg`}
          />
          <p className="absolute z-1000004 h-[3.625rem] w-[21.438rem] text-white font-bold fsz-24ptr m-auto flex justify-center items-center">
            {title}
          </p>
        </div>
      );
  }
};

export default Ribbon;
