// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX
import { motion } from 'framer-motion';
import React from 'react';

type BallonProps = React.ButtonHTMLAttributes<HTMLDivElement>;

const Balloon = React.forwardRef<HTMLDivElement, BallonProps>((props, ref) => {
  return (
    <div
      ref={ref}
      className="bg-white relative rounded-lg
    before:content-[''] before:absolute before:-translate-x-1/2 before:left-1/2
    before:top-full before:border-4  before:border-transparent
    before:border-t-white"
    >
      {props.children}
    </div>
  );
});
Balloon.displayName = 'Balloon';
export default motion(Balloon);
