// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import create from 'zustand';

type DisplayErrorStore = {
  isDisplayError: boolean;
  displayError: () => void;
};

export const useDisplayErrorStore = create<DisplayErrorStore>((set) => ({
  isDisplayError: false,
  displayError: () => set({ isDisplayError: false }),
}));
