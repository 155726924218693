import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import React, { useImperativeHandle, useRef, useState } from 'react';

import { IUnlockEffectAnimator, PieceConfig } from './types';

const UnlockEffect = React.forwardRef<IUnlockEffectAnimator>(function UnlockEffect(_, ref) {
  const pieceAnimControls = useAnimation();
  const rootRef = useRef<HTMLDivElement>(null);
  const [images, setImages] = useState({
    piece: 'piece_normal_active.png',
    effect: 'effect_unlock.png',
  });
  const [showEffect, setShowEffect] = useState(false);

  const animate = async (top: number, left: number, piece: PieceConfig) => {
    // HACK: 二回目以降のアンロックにおいて、Activeコマのアイコンが、移動前に一瞬映ってしまうので
    // まずコマアイコンのopacityを0にしてからポジションを移動している
    await pieceAnimControls.start({
      opacity: 0,
      transition: {
        duration: 0,
      },
    });
    if (rootRef.current) {
      rootRef.current.style.top = `${top}px`;
      rootRef.current.style.left = `${left}px`;
    }

    setupImages(piece);

    await new Promise((resolve) => setTimeout(resolve, 200));
    setShowEffect(true);
    await new Promise((resolve) => setTimeout(resolve, 200));
    await pieceAnimControls.start({
      opacity: 1,
      transition: {
        ease: 'linear',
        duration: 0.4,
      },
    });
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setShowEffect(false);
  };

  const setupImages = (piece: PieceConfig) => {
    if (piece.isGoal) {
      setImages({
        piece: piece.tipsIndex ? 'piece_goal_tip.png' : 'piece_goal_active.png',
        effect: 'effect_unlock.png',
      });
    } else if (piece.isSpecial) {
      setImages({
        piece: 'piece_special_active.png',
        effect: 'effect_unlock_special.png',
      });
    } else {
      setImages({
        piece: piece.tipsIndex ? 'piece_normal_tip.png' : 'piece_normal_active.png',
        effect: 'effect_unlock.png',
      });
    }
  };

  useImperativeHandle(ref, () => ({ animate: animate }));

  return (
    <div ref={rootRef} className="absolute pointer-events-none" style={{ top: 0, left: 0 }}>
      <motion.div animate={pieceAnimControls} className="absolute w-32" style={{ opacity: 0 }}>
        <img
          className="-translate-x-1/2 -translate-y-1/2"
          src={`/sugoroku/${images.piece}`}
          alt="piece"
        />
      </motion.div>
      <AnimatePresence>
        {showEffect && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute w-72"
          >
            <img
              src={`/sugoroku/${images.effect}`}
              alt=""
              style={{
                translate: '-50% -50%',
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
});

export default UnlockEffect;
