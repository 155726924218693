// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import storage from '@/utils/storage';

import { useRefreshToken } from '../api/refreshToken';

export type AuthenticationProps = {
  children: React.ReactNode;
  errorFallback: React.ReactNode;
};

const Authentication = ({ children, errorFallback }: AuthenticationProps) => {
  const [searchParams] = useSearchParams();
  const [authorized, setAuthorized] = useState(false);
  const [queryToken, setQueryToken] = useState('');

  const { isLoading, data } = useRefreshToken({ token: queryToken });

  useEffect(() => {
    const gameId = searchParams.get('game_id');
    switch (gameId) {
      case process.env.REACT_APP_TOURISM_GAME_ID:
        storage.setSkin('tourism');
        break;
      default:
        storage.setSkin('gourmet');
    }

    const token = searchParams.get('token');
    if (token) {
      setQueryToken(token);
    } else {
      const refToken = storage.getToken('refresh');
      if (refToken) {
        setQueryToken(refToken);
      }
    }

    const tokenExpired = searchParams.get('tokenexpired');
    if (tokenExpired !== null) {
      setAuthorized(false);
      return;
    }
  }, [searchParams]);

  useEffect(() => {
    if (data?.data) {
      storage.setToken('access', data.data.accessToken);
      storage.setToken('refresh', data.data.refreshToken);
      setAuthorized(true);
      const url = window.location.href.split('?')[0];
      window.history.replaceState({ path: url }, '', url);
    }
  }, [data]);

  return <>{authorized ? children : !isLoading && errorFallback}</>;
};

export default Authentication;
