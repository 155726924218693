import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import Balloon from '@/components/Elements/Balloon';

import { useOnboardingStatusStore } from '../onboarding/stores/OnboardingStatusStore';

import { StageConfig } from './types';

type TipsHighlightProps = {
  backgroundHeight: number;
  backgroundWidth: number;
  stageConfig: StageConfig;
};

const TipsHighlight = ({ backgroundHeight, backgroundWidth, stageConfig }: TipsHighlightProps) => {
  const { t } = useTranslation();
  const { currentStep: onboardingStep, proceed: proceedOnboarding } = useOnboardingStatusStore();
  const piece = stageConfig.pieces[0];

  return (
    <>
      <AnimatePresence>
        {onboardingStep == 'tipsHighlight' && (
          <motion.div
            className="absolute z-10 top-0 left-0"
            style={{
              width: backgroundWidth,
              height: backgroundHeight,
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => proceedOnboarding()}
          >
            <div
              className="z-20 absolute max-w-[10rem]"
              style={{
                top: piece.top,
                left: piece.left,
                translate: '-50% -180%',
              }}
            >
              <Balloon>
                <p className="p-4">{t('sugoroku.tips_highlight')}</p>
              </Balloon>
            </div>
            <svg width="100%" height="100%" viewBox={`0 0 ${backgroundWidth} ${backgroundHeight}`}>
              <rect width="100%" height="100%" fillOpacity="0.6" mask="url(#mask)" />
              <mask id="mask" width={backgroundWidth} height={backgroundHeight}>
                <rect width="100%" height="100%" fill="#fff" />
                <circle r="50" cx={piece.left} cy={piece.top} />
              </mask>
            </svg>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default TipsHighlight;
