// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import create from 'zustand';

import { Item } from '../types';

type ItemConfirmationStore = {
  item?: Item;
  currentStep: ItemConfirmationStep;
  start: (item: Item) => void;
  proceed: () => void;
};

export type ItemConfirmationStep =
  | 'animate'
  | 'getPiece'
  | 'itemTips'
  | 'badge'
  | 'mutate'
  | 'complete';

const steps: ItemConfirmationStep[] = [
  'animate',
  'getPiece',
  'itemTips',
  'badge',
  'mutate',
  'complete',
];

const useItemConfirmationStore = create<ItemConfirmationStore>((set) => ({
  item: undefined,
  currentStep: 'complete',
  start: (item: Item) =>
    set(() => {
      return { item: item, currentStep: 'animate' };
    }),
  proceed: () =>
    set((state) => {
      return {
        ...state,
        currentStep: steps.at(steps.indexOf(state.currentStep) + 1) ?? 'complete',
      };
    }),
}));

export default useItemConfirmationStore;
