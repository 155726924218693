import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import { SugorokuStageConfig } from '@/config';

import { useBadges } from '../badge/api/getBadges';
import MyBadgesPopup from '../badge/MyBadgesPopup';
import { useItems, useItemsMultipleStage } from '../item/api/getItems';
import ItemAcquisitionConditionPopup from '../item/ItemAcquisitionConditionPopup';
import ItemConfirmation from '../item/ItemConfirmation';
import ItemTipsPopup from '../item/ItemTipsPopup';
import { StageItem } from '../item/types';
import { useUserNotification } from '../notifications/api/getUserNotifications';
import NotificationPopup from '../notifications/NotificationPopup';
import { UserNotification } from '../notifications/types';
import { useOnboardingStatusStore } from '../onboarding/stores/OnboardingStatusStore';
import { useSkipItem } from '../skipItem/api/getSkipItems';
import ConfirmConsumeSkipItemPopup from '../skipItem/ConfirmConsumeSkipItemPopup';
import MySkipItemPopup from '../skipItem/MySkipItemPopup';
import SkipItemButton from '../skipItem/SkipItemButton';
import SkipItemConfirmation from '../skipItem/SkipItemConfirmation';
import ClearCelebration from '../stage/ClearCelebration';
import { Stage } from '../stage/types';

import Board from './Board';
import { useSkin } from './hooks';
import Map from './Map';
import RemainingCounter from './RemainingCounter';
import { PieceConfig } from './types';

export type SugorokuProps = {
  stage: Stage;
};

const Sugoroku = ({ stage }: SugorokuProps) => {
  const { t } = useTranslation(['common', 'pieces']);
  const stageConfig = SugorokuStageConfig().find((x) => x.id == stage.id)!;
  const { data: currentStageItems } = useItems({ stageId: stage.id });
  const getAllStageItems = useItemsMultipleStage(
    SugorokuStageConfig()
      .filter((x) => x.order <= stageConfig.order)
      .map((x) => x.id)
  );
  const { data: skipItems } = useSkipItem({});
  const { data: badges } = useBadges();
  const { data: userNotification } = useUserNotification();
  const skin = useSkin();
  const confirmed = (currentStageItems?.data ?? []).filter((x) => x.confirmed);
  const remain =
    stageConfig.pieces.filter((x) => !x.isSpecial).length -
    confirmed.filter((x) => x.stageItem.itemType != 'special').length;
  const [openTips, setOpenTips] = useState({ open: false, stageItem: {} as StageItem });
  const [openItemRequirement, setOpenItemRequirement] = useState({
    open: false,
    piece: {} as PieceConfig,
  });
  const [openConsumeSkipItem, setOpenConsumeSkipItem] = useState({ open: false, stageItemId: -1 });
  const [openMyBadge, setOpenMyBadge] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [openMySkipItem, setOpenMySkipItem] = useState(false);
  const [newNotification, setNewNotification] = useState<UserNotification | undefined>();
  const { currentStep: onboardingStep } = useOnboardingStatusStore();

  const allStageItems = () => getAllStageItems.filter((x) => x.data).flatMap((x) => x.data!.data);

  const onPieceClick = (piece: PieceConfig) => {
    const item = allStageItems().find((x) => x.stageItem.id == piece.stageItemId);
    if (item?.confirmed) {
      if (item.stageItem.description?.length > 0)
        setOpenTips({ open: true, stageItem: item.stageItem });
    } else {
      setOpenItemRequirement({ open: true, piece: piece });
    }
  };

  useEffect(() => {
    const newNotification = userNotification?.data.find((x) => !x.confirmed);
    setNewNotification(newNotification);
  }, [userNotification]);

  return (
    <div className="absolute h-full w-full">
      {currentStageItems && (
        <>
          <Board
            stageConfig={stageConfig}
            userItems={currentStageItems.data}
            onPieceClick={onPieceClick}
          />
          <div className="absolute top-0 w-full pt-2 px-1 lg:px-[24px] lg:pt-[24px] pointer-events-none flex flex-col">
            <div className="relative w-full">
              <div className="flex items-center">
                <img className="h-[3rem]" src="/title_ribbon/top_head.png" alt=""></img>
                <div className="flex-1">
                  <img
                    className="h-[3rem] w-full object-fill"
                    src="/title_ribbon/top_body.png"
                    alt=""
                  ></img>
                </div>
                <img className="h-[3rem]" src="/title_ribbon/top_tail.png" alt=""></img>
              </div>
              <div
                className="absolute px-4 flex flex-row items-center top-1/2 -translate-y-1/2
                    font-bold text-white fsz-20px"
              >
                <img className="w-5" src="./sugoroku_map_flag.svg" alt=""></img>
                <p className="mx-1 font-outlined-2-black">{stageConfig.order}</p>
                <div
                  className="flex flex-wrap justify-center content-center ml-2
                   bg-blackGray rounded-3xl fsz-14px h-[30px] w-[52px]"
                >
                  <span>{t('sugoroku.remaining_counter.goal')}</span>
                </div>
                <img
                  className="mx-2 h-6"
                  src={`./top_goal_text/${skin}/${stageConfig.key}.png`}
                  alt=""
                />
              </div>
            </div>
            <div className="flex flex-col gap-2 items-end px-2 lg:px-0 mt-2">
              <Button
                buttonSize="small"
                colorType="blue"
                onClick={() => setOpenMap(true)}
                className="pointer-events-auto"
                text={t('sugoroku.remaining_counter.to_map')}
              />
              {skipItems && (
                <SkipItemButton
                  className="mt-4 pointer-events-auto"
                  skipItems={skipItems.data}
                  onClick={() => setOpenMySkipItem(true)}
                />
              )}
              {badges && (
                <div className="pointer-events-auto" onClick={() => setOpenMyBadge(true)}>
                  <img className="w-[4.5rem]" src="./badge/button.png" alt="badges" />
                </div>
              )}
            </div>
          </div>
          <div className="short:invisible absolute bottom-0 flex flex-col gap-6 py-8 px-4">
            <RemainingCounter confirmed={confirmed.length} remain={remain} />
          </div>
        </>
      )}

      <ItemTipsPopup
        stageItem={openTips.stageItem}
        open={openTips.open}
        onClose={() => setOpenTips({ ...openTips, open: false })}
      />
      {currentStageItems && allStageItems && (
        <>
          <ItemAcquisitionConditionPopup
            piece={openItemRequirement.piece}
            openDialog={openItemRequirement.open}
            items={allStageItems()}
            skipItems={skipItems?.data ?? []}
            onClose={() => setOpenItemRequirement({ ...openItemRequirement, open: false })}
            openConsume={(stageItemId) =>
              setOpenConsumeSkipItem({ stageItemId: stageItemId, open: true })
            }
            activeStage={stageConfig}
          />
          <SkipItemConfirmation userItems={allStageItems()} />
          <Map
            confirmed={confirmed.length}
            remain={remain}
            currentPiece={(() => {
              const currentItem = currentStageItems.data.filter((x) => x.confirmed).at(-1);
              const currentPiece = stageConfig.pieces.find(
                (x) => x.stageItemId == currentItem?.stageItem.id
              );
              return currentPiece ?? stageConfig.pieces[0];
            })()}
            open={openMap}
            onClose={() => setOpenMap(false)}
            stageConfig={stageConfig}
          />
        </>
      )}
      {skipItems && (
        <>
          <ConfirmConsumeSkipItemPopup
            openDialog={openConsumeSkipItem.open}
            stageItemId={openConsumeSkipItem.stageItemId}
            skipItems={skipItems.data}
            onClose={() => setOpenConsumeSkipItem({ ...openConsumeSkipItem, open: false })}
          />
          <MySkipItemPopup
            open={openMySkipItem}
            onClose={() => setOpenMySkipItem(false)}
            skipItems={skipItems.data}
          />
        </>
      )}
      {badges && (
        <MyBadgesPopup
          badges={badges.data}
          openDialog={openMyBadge}
          onClose={() => setOpenMyBadge(false)}
        />
      )}
      {onboardingStep == undefined && (
        <>
          {newNotification ? (
            <NotificationPopup userNotification={newNotification} />
          ) : (
            currentStageItems &&
            badges && (
              <>
                <ItemConfirmation
                  userItems={currentStageItems.data}
                  badges={badges.data}
                  stageConfig={stageConfig}
                />
                <ClearCelebration
                  userItems={currentStageItems.data}
                  badges={badges.data}
                  stageConfig={stageConfig}
                />
              </>
            )
          )}
        </>
      )}
    </div>
  );
};

export default Sugoroku;
