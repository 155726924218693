// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

const storagePrefix = 's-gaas_';
const clearCelebrationShownKey = `${storagePrefix}clear_celebration_shown`;
export type tokenType = 'refresh' | 'access';

const storage = {
  getToken: (type: tokenType) => {
    return window.localStorage.getItem(`${storagePrefix}${type}_token`);
  },
  setToken: (type: tokenType, token: string) => {
    window.localStorage.setItem(`${storagePrefix}${type}_token`, token);
  },
  clearToken: (type: tokenType) => {
    window.localStorage.removeItem(`${storagePrefix}${type}_token`);
  },
  getClearCelebrationShown: (stageId: number) => {
    return readArray<number>(clearCelebrationShownKey).includes(stageId);
  },
  setClearCelebrationShown: (stageId: number) => {
    const arr = [...readArray<number>(clearCelebrationShownKey)];
    if (!arr.find((x) => x == stageId)) arr.push(stageId);
    window.localStorage.setItem(clearCelebrationShownKey, JSON.stringify(arr));
  },
  setSkin: (skin: string) => {
    window.localStorage.setItem(`${storagePrefix}skin`, skin);
  },
  getSkin: () => {
    return window.localStorage.getItem(`${storagePrefix}skin`);
  },
};

function readArray<T>(key: string) {
  const str = window.localStorage.getItem(key) ?? '';
  if (str.length > 0) {
    return JSON.parse(str) as T[];
  } else {
    return [];
  }
}

export default storage;
