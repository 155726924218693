// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';
import Ribbon from '@/components/Elements/Ribbon';

import { SkipItem } from './types';

export type GetSkipItemPopupProp = {
  skipItems: SkipItem[];
  onClose?: () => void;
};

const GetSkipItemPopup = ({ skipItems, onClose = () => {} }: GetSkipItemPopupProp) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(true);
  const totalTaskCount = (skipItems.at(0)?.gameSkipItem.numberOfTaskTime ?? 0) * skipItems.length;

  return (
    <Dialog
      width="middle"
      openDialog={openDialog}
      borderColor="blueLight"
      backgroundColor="gradientSkyBlue"
    >
      <div className="flex flex-col items-center">
        <Ribbon
          title={t('skip_item.get_skip_item.task_times', { count: totalTaskCount })}
          type={'centerBlue'}
        />
        <div className="flex flex-col w-full mt-6 gap-6 items-center bg-[url('/public/tip_bg.svg')] bg-no-repeat bg-center">
          <p className="fsz-16ptr">{t('skip_item.get_skip_item.present')}</p>
          <p className="fsz-32ptr font-bold">{t('skip_item.get_skip_item.title')}</p>
          <img className="w-[8.125rem]" src="./skip_item_icon.png" alt="" />
          <div className="fsz-16ptr text-center whitespace-pre-wrap">
            <p className="font-bold">
              {t('skip_item.get_skip_item.got_skip_item_count', {
                count: skipItems.filter((x) => !x.confirmed).length,
              })}
            </p>
            <p>{t('skip_item.get_skip_item.description')}</p>
          </div>
        </div>
        <Button
          className="mt-6"
          text={t('common.close')}
          colorType={'pink'}
          buttonSize={'large'}
          onClick={() => {
            onClose();
            setOpenDialog(false);
          }}
        />
      </div>
    </Dialog>
  );
};
export default GetSkipItemPopup;
