// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';

import AchievementLogItem from './AchievementLogItem';
import { Item } from './types';

export type AchievementLogPopupProps = {
  itemList: Item[];
  onClose: () => void;
};

const AchievementLogPopup = ({ itemList, onClose }: AchievementLogPopupProps) => {
  // achievementDetailがnullでない（＝通常にTaskをこなして入手した）もののみを表示
  const achievementList = itemList.filter((x) => x.achievementDetail != null);
  const initialDelay = 3.4;
  // 一行あたり何秒間表示するか
  const itemDelay = 3.0;
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(true);

  return (
    <>
      <Dialog
        openDialog={openDialog}
        borderColor={'blueLight'}
        backgroundColor="dialogBlue"
        backdropColor="opacity"
        onClose={onClose}
      >
        <div className="relative -top-[5rem]">
          <div className="absolute top-0 left-0 right-0 bottom-0 m-auto max-w-[18.75rem]">
            <img src="./title_achievements_all.png" className="w-[100%] h-auto" alt="itemImage" />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-center items-center h-full bg-blackGray rounded-[1.25rem] mx-[3.125rem] mb-5 py-1">
            <p className="fsz-20ptr text-white font-bold">
              {t('item.achievement_log_popup.title', {
                count: achievementList.length,
              })}
            </p>
          </div>
          <div className="mt-2 sm:max-h-[30vh] smLarge:max-h-[30vh] max-h-[35vh] overflow-scroll mb-1">
            {achievementList.map((item, index) => {
              return (
                <AchievementLogItem
                  item={item}
                  index={index}
                  key={index}
                  itemOutDisplayItemFirstTime={initialDelay}
                  initialDelay={initialDelay}
                  itemDelay={itemDelay}
                />
              );
            })}
          </div>
          <div className="flex items-center justify-center">
            <Button
              className="mt-5 mx-[3.75rem]"
              text={t('common.close')}
              colorType={'pink'}
              buttonSize={'large'}
              onClick={() => {
                setOpenDialog(false);
              }}
            />
          </div>
        </div>
      </Dialog>
      {openDialog && (
        <div className="absolute flex pointer-events-none flex-row justify-center items-center z-1000004 bottom-0 w-full">
          <div className="flex items-center justify-center mt-2">
            <motion.img
              className="ml-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                ease: 'easeIn',
                duration: 0.3,
                // 0.8 is delay transition
                delay: initialDelay + 0.8 + achievementList.length * itemDelay,
              }}
              src="./achievement_msg_border.svg"
              alt="msg"
            ></motion.img>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                ease: 'easeIn',
                duration: 0.3,
                // 1 is delay transition
                delay: initialDelay + 1 + achievementList.length * itemDelay,
              }}
              className="mr-5 absolute whitespace-pre-wrap text-left text-black fsz-18ptr"
            >
              {t('item.achievement_log_popup.dialogue')}
            </motion.p>
          </div>
          <motion.div
            className="bottom-0 right-0"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              ease: 'easeIn',
              duration: 0.3,
              // 0.5 is delay transition
              delay: initialDelay + 0.5 + achievementList.length * itemDelay,
            }}
          >
            <img
              className="max-h-40 translate-y-2"
              src="./character_clapping.png"
              alt="character"
            ></img>
          </motion.div>
        </div>
      )}
    </>
  );
};

export default AchievementLogPopup;
