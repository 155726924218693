import create from 'zustand';

export type ClearCelebrationStep =
  | 'effect'
  | 'achievementLog'
  | 'badge'
  | 'goalAction'
  | 'introduceNext'
  | 'closed';

export const ClearCelebrationSteps: ClearCelebrationStep[] = [
  'effect',
  'achievementLog',
  'badge',
  // アライアンス側でゴールアクションの定義がないため、一時的に非表示にする
  // ticket: https://www.notion.so/d58aff2653d14c1193d8f16d4b04253a?pvs=4
  // 'goalAction',
  'introduceNext',
  'closed',
];

export type ClearCelebrationStatus = {
  currentStep?: ClearCelebrationStep;
  start: () => void;
  skipTo: (step: ClearCelebrationStep) => void;
  proceed: () => void;
  reset: () => void;
};

export const useClearCelebrationStore = create<ClearCelebrationStatus>((set) => ({
  currentStep: undefined,
  start: () =>
    set(() => ({
      currentStep: 'effect',
    })),
  proceed: () => {
    set((state) => ({
      ...state,
      currentStep: ClearCelebrationSteps.at(ClearCelebrationSteps.indexOf(state.currentStep!) + 1),
    }));
  },
  skipTo: (step: ClearCelebrationStep) => {
    set((state) => ({
      ...state,
      currentStep: step,
    }));
  },
  reset: () =>
    set(() => ({
      currentStep: undefined,
    })),
}));
