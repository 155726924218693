// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { BACKEND_ENV } from '@/config';
import CreateUser from '@/dev_only/alliance/CreateUser';
import GetAccessToken from '@/dev_only/alliance/GetAccessToken';

const Unauthorized = () => {
  return (
    <div className="flex flex-col items-start">
      <div>unauthorized</div>
      {BACKEND_ENV != 'production' && (
        <div>
          <CreateUser className="border p-5 mt-5" />
          <GetAccessToken className="border p-5 mt-5" />
        </div>
      )}
    </div>
  );
};

export default Unauthorized;
