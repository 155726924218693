// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { Item } from '../item/types';

import { useSkipItem } from './api/getSkipItems';
import { usePatchSkipItemConfirmed } from './api/patchSkipItemConfirm';
import GetSkipItemPopup from './GetSkipItemPopup';

type SkipItemConfirmationProps = {
  userItems: Item[];
};

const SkipItemConfirmation = ({ userItems }: SkipItemConfirmationProps) => {
  const { data: skipItems } = useSkipItem();
  const patchSkipItemConfirmation = usePatchSkipItemConfirmed();
  const newSkipItems = (skipItems?.data ?? []).filter((x) => !x.confirmed);

  return (
    <>
      {userItems.every((x) => x.confirmed) && newSkipItems.length > 0 && (
        <GetSkipItemPopup
          skipItems={skipItems!.data}
          onClose={() => {
            newSkipItems.forEach((x) => {
              patchSkipItemConfirmation.mutateAsync({ skipItemId: x.id });
            });
          }}
        />
      )}
    </>
  );
};

export default SkipItemConfirmation;
