// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './polyfills';

import './index.css';
import './service/i18n';
import App from './App';
import { BUGSNAG_API_KEY } from './config';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
