// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';

export type ComingSoonPopup = {
  onClose: () => void;
};

const ComingSoonPopup = ({ onClose }: ComingSoonPopup) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(true);

  return (
    <>
      <Dialog
        openDialog={openDialog}
        borderColor={'blueLight'}
        backgroundColor="dialogBlue"
        backdropColor="opacity"
        onClose={onClose}
      >
        <div className="flex flex-col items-center">
          <div className="relative max-w-[220px] mb-4">
            <img src="./coming_soon.svg" className="w-full h-auto" alt="title" />
          </div>
          <div className="flex flex-col smLarge:max-h-[calc(78vh-130px)] overflow-y-auto">
            <p className="fsz-16ptr text-center whitespace-pre-wrap">{t('common.coming_soon')}</p>
            <div className="min-h-[200px] bg-no-repeat bg-center flex flex-col items-center justify-center">
              <div className="max-w-[200px] mt-6">
                <img src="./hug_cat.svg" className="w-[100%] h-auto" alt="itemImageDemo" />
              </div>
            </div>
            <Button
              className="md:mx-[60px] mb-2 mt-6 mx-[10px]"
              text={t('common.close')}
              colorType={'pink'}
              buttonSize={'medium'}
              onClick={() => {
                setOpenDialog(false);
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default ComingSoonPopup;
