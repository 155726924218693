// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { t } from 'i18next';
import { useEffect, useState } from 'react';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';
import { SugorokuStageConfig } from '@/config';

import { addEvent } from '../event/api/addEvent';
import GotoConsumeSkipItem from '../skipItem/GotoConsumeSkipItem';
import { SkipItem } from '../skipItem/types';
import { PieceConfig, StageConfig } from '../sugoroku/types';

import { useTaskAndItem } from './api/getTask';
import { Item } from './types';

export type ItemRequirementPopupProps = {
  piece: PieceConfig;
  openDialog: boolean;
  activeStage: StageConfig;
  items: Item[];
  skipItems: SkipItem[];
  onClose: () => void;
  openConsume: (stageItemId: number) => void;
};

const ItemAcquisitionConditionPopup = ({
  piece,
  openDialog,
  activeStage,
  onClose,
  items,
  skipItems,
  openConsume,
}: ItemRequirementPopupProps) => {
  const { data, refetch } = useTaskAndItem({
    stageItemId: piece.stageItemId,
    config: { enabled: false },
  });
  const [nextStageItemId, setNextStageItemId] = useState<number | undefined>();

  useEffect(() => {
    if (openDialog) {
      refetch();
    }
  }, [openDialog]);

  useEffect(() => {
    const itemIds = items.map((i) => i.stageItem.id);
    const stages = SugorokuStageConfig().filter((x) => x.order <= activeStage.order);
    const nextPiece = stages
      .flatMap((x) => x.pieces)
      .filter((x) => x.isSpecial == piece.isSpecial)
      .filter((x) => !itemIds.includes(x.stageItemId))
      .at(0);
    setNextStageItemId(nextPiece?.stageItemId);
  }, [piece, activeStage, items]);

  useEffect(() => {
    if (!data) return;
    const eventName = (() => {
      if (data.stageItem.itemType === 'normal') {
        return 'tap_normal_square_def';
      } else {
        return 'tap_special_square_def';
      }
    })();
    addEvent({
      eventName,
      itemId: piece.stageItemId,
      taskId: data.task.id,
      stageId: data.stageItem.stageId,
    });
  }, [data]);

  const gotoTraining = async () => {
    if (!data) {
      return;
    }
    const eventName = (() => {
      if (data.stageItem.itemType === 'normal') {
        return 'move_app_to_get_normal_square';
      } else {
        return 'move_app_to_get_special_square';
      }
    })();

    await addEvent({
      eventName,
      itemId: piece.stageItemId,
      taskId: data.task.id,
      stageId: data.stageItem.stageId,
    });
    window.location.href = data.task.url;
  };

  return (
    <>
      <Dialog
        openDialog={openDialog}
        borderColor={piece?.isSpecial ? 'purpleLight' : 'blueLight'}
        backgroundColor={piece?.isSpecial ? 'gradientPurpleLight' : 'gradientSkyBlue'}
        boxShadow={piece?.isSpecial}
      >
        <div className="flex flex-col items-center text-center">
          <img
            className="w-20 md:w-24"
            src={piece?.isSpecial ? '/exclamation.svg' : '/question.svg'}
            alt="?"
          />
          <p className="mt-3 md:mt-6 whitespace-pre-wrap leading-5 md:leading-6">
            {data?.task.description}
          </p>
          {piece?.isSpecial ? (
            <button onClick={gotoTraining} className="mt-5 md:mt-7 focus:outline-none">
              <img src="./sugoroku/button_goto_mission.png" alt="button goto mission" />
            </button>
          ) : (
            <Button
              text={t('item.item_acquition_condition_popup.go_to_training')}
              className="mt-4 md:mt-7"
              colorType="orange"
              buttonSize="large"
              onClick={gotoTraining}
            />
          )}
          {nextStageItemId && !skipItems.every((x) => x.used) && (
            <GotoConsumeSkipItem
              className="mt-3 md:mt-5"
              openConsume={() => {
                openConsume(nextStageItemId);
                onClose();
              }}
            />
          )}
          <Button
            text={t('common.close')}
            onClick={onClose}
            colorType="pink"
            buttonSize="large"
            className="mt-3 md:mt-5"
          />
        </div>
      </Dialog>
    </>
  );
};

export default ItemAcquisitionConditionPopup;
