// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useEffect, useState } from 'react';

import { useLoadingStore } from '@/stores/loading';

import BadgeAcquisitionPopup from '../badge/BadgeAcquisitionPopup';
import { Badge } from '../badge/types';
import { addEvent } from '../event/api/addEvent';
import { PieceConfig, StageConfig } from '../sugoroku/types';

import { usePatchItemConfirmed } from './api/patchItemConfirm';
import GetPiecePopup from './GetPiecePopup';
import ItemTipsPopup from './ItemTipsPopup';
import useItemConfirmationStore from './stores/ItemConfirmationStore';
import { Item } from './types';

type ItemConfirmationProps = {
  userItems: Item[];
  badges: Badge[];
  stageConfig: StageConfig;
};

const ItemConfirmation = ({ userItems, badges, stageConfig }: ItemConfirmationProps) => {
  const { item: currentItem, currentStep, proceed } = useItemConfirmationStore();
  const patchConfirmation = usePatchItemConfirmed({
    stageId: stageConfig.id,
  });
  const [confirmationCount, setConfirmationCount] = useState(0);
  const { start: startItemConfirmation } = useItemConfirmationStore();
  const itemBadge = badges.find((x) => x.itemId == currentItem?.id);
  const [pieceConfig, setPieceConfig] = useState({} as PieceConfig);
  const { processes } = useLoadingStore();

  useEffect(() => {
    switch (currentStep) {
      case 'mutate':
        if (currentItem)
          patchConfirmation
            .mutateAsync({
              itemId: currentItem.id,
            })
            .finally(() => proceed());
        break;
      case 'badge':
        if (!itemBadge) proceed();
        break;
      case 'itemTips':
        if (!pieceConfig.tipsIndex) {
          proceed();
        }
        break;
    }
  }, [currentStep]);

  useEffect(() => {
    if (processes.size > 0) return;
    const nextItem = userItems?.find((x) => !x.confirmed);
    const pieceConfig = stageConfig.pieces.find((x) => x.stageItemId == nextItem?.stageItem.id);
    if (pieceConfig && nextItem && currentStep == 'complete') {
      startItemConfirmation(nextItem);
      setConfirmationCount(confirmationCount + 1);
      setPieceConfig(pieceConfig);
    }
  }, [userItems, processes]);

  useEffect(() => {
    if (confirmationCount === 2) {
      addEvent({ eventName: 'how_continue_square_num', stageId: stageConfig.id });
    }
  }, [confirmationCount]);

  switch (currentStep) {
    case 'getPiece':
      return <GetPiecePopup pieceConfig={pieceConfig} onClose={proceed} />;
    case 'itemTips':
      return <ItemTipsPopup open={true} stageItem={currentItem!.stageItem} onClose={proceed} />;
    case 'badge':
      return (
        <>
          {itemBadge && (
            <BadgeAcquisitionPopup piece={pieceConfig} badge={itemBadge} onClose={proceed} />
          )}
        </>
      );
    default:
      return <></>;
  }
};

export default ItemConfirmation;
