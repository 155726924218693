import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

import { SugorokuStageConfig } from '@/config';
import storage from '@/utils/storage';

import { useAlliance } from '../aliance/api/getAlliance';
import { useGoalAction } from '../aliance/api/getGoalAction';
import GoalActionPopup from '../aliance/GoalActionPopup';
import BadgeAcquisitionPopup from '../badge/BadgeAcquisitionPopup';
import { Badge } from '../badge/types';
import { addEvent } from '../event/api/addEvent';
import AchievementLogPopup from '../item/AchievementLogPopup';
import { Item } from '../item/types';
import ClearEffect from '../sugoroku/ClearEffect';
import { StageConfig } from '../sugoroku/types';

import { useUserStage } from './api/getUserStage';
import { useSelectStage } from './api/selectStage';
import ComingSoonPopup from './ComingSoonPopup';
import GoalIntroductionPopup from './GoalIntroductionPopup';
import { useClearCelebrationStore } from './stores/ClearCelebrationStore';

type ClearCelebrationProps = {
  userItems: Item[];
  badges: Badge[];
  stageConfig: StageConfig;
};

export const ClearCelebration = ({ userItems, badges, stageConfig }: ClearCelebrationProps) => {
  const { start, currentStep, proceed, skipTo, reset } = useClearCelebrationStore();
  const selectStage = useSelectStage();
  const { data: userStage } = useUserStage({ stageId: stageConfig.id });
  const { data: alliance } = useAlliance();
  const { data: goalAction } = useGoalAction({
    allianceId: alliance?.data.id ?? -1,
    config: { enabled: alliance != undefined },
  });
  const [isFinalStage, setIsFinalStage] = useState(false);
  const clearBadge = badges.find((x) => x.itemId == undefined && x.stageId == stageConfig.id);
  const nextStage = SugorokuStageConfig().find((x) => x.order == stageConfig.order + 1);

  useEffect(() => {
    const userItemsMap = new Map<number, boolean>();
    for (const item of userItems) {
      userItemsMap.set(item.stageItem.id, item.confirmed);
    }
    const everyItemConfirmed = stageConfig.pieces
      .filter((x) => !x.isSpecial)
      .every((x) => userItemsMap.get(x.stageItemId));
    if (userStage?.data.cleared && everyItemConfirmed) {
      if (storage.getClearCelebrationShown(stageConfig.id)) {
        skipTo('introduceNext');
      } else {
        start();
      }
    }
  }, [userStage, userItems]);

  useEffect(() => {
    switch (currentStep) {
      case 'goalAction':
        if (goalAction && goalAction.data == undefined) proceed();
        storage.setClearCelebrationShown(stageConfig.id);
        break;
      case 'badge':
        if (!clearBadge) proceed();
        break;
      case 'closed':
        if (nextStage) {
          selectStage.mutateAsync({ stageId: nextStage.id }).finally(() => reset());
        }
        break;
    }
  }, [currentStep]);

  useEffect(() => {
    setIsFinalStage(SugorokuStageConfig().at(-1)?.id == stageConfig.id);
  }, [stageConfig]);

  const content = () => {
    switch (currentStep) {
      case 'effect':
        addEvent({ eventName: 'clear_stage', stageId: stageConfig.id });
        return (
          <ClearEffect
            isFinalStage={isFinalStage}
            goalPiece={stageConfig.pieces.find((x) => x.isGoal)!}
            onClose={proceed}
          />
        );
      case 'achievementLog':
        return <AchievementLogPopup itemList={userItems} onClose={proceed} />;
      case 'goalAction':
        return (
          <>
            {goalAction?.data && <GoalActionPopup goalAction={goalAction.data} onClose={proceed} />}
          </>
        );
      case 'badge':
        return (
          <>
            {clearBadge && (
              <BadgeAcquisitionPopup
                piece={stageConfig.pieces.find((x) => x.isGoal)!}
                totalPieceCount={userItems.length}
                badge={clearBadge}
                onClose={proceed}
              />
            )}
          </>
        );
      case 'introduceNext':
        if (nextStage) {
          return <GoalIntroductionPopup stageConfig={nextStage} onClose={proceed} />;
        } else {
          addEvent({ eventName: 'achieve_comming_soon', stageId: stageConfig.id });
          return <ComingSoonPopup onClose={proceed} />;
        }
      default:
        return <></>;
    }
  };

  return (
    <AnimatePresence>
      {currentStep && currentStep != 'closed' && (
        <motion.div
          className="fixed top-0 left-0 flex flex-col items-center w-screen h-screen z-100 bg-blur"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <img className="absolute h-full object-cover" src="./sugoroku/confetti.png" alt="" />
          {content()}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default ClearCelebration;
