// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { Response } from '@/types';

import { Item, StageItem } from '../../item/types';
import { SkipItem } from '../types';

export type ConsumeSkipItemDTO = {
  stageItemId: number;
  skipItemId: number;
};

type consumeResponse = Item & {
  stageId: number;
  skipItem: SkipItem;
  stageItem: StageItem;
};

export const consumeSkipItem = ({
  stageItemId,
  skipItemId,
}: ConsumeSkipItemDTO): Promise<Response<consumeResponse>> => {
  return axios.post(`game/items`, { stageItemId, skipItemId });
};

type ConsumeSkipItemOptions = {
  config?: MutationConfig<typeof consumeSkipItem>;
};

export const useConsumeSkipItem = ({ config }: ConsumeSkipItemOptions = {}) => {
  return useMutation({
    onSuccess: (data) => {
      queryClient.invalidateQueries('skipItems');
      queryClient.invalidateQueries('badges');
      queryClient.invalidateQueries(['items', data.data.stageId]);
      queryClient.invalidateQueries(['userStage', data.data.stageId]);
    },
    ...config,
    mutationFn: consumeSkipItem,
  });
};
