// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

export const API_URL = process.env.REACT_APP_API_URL as string;
export const BACKEND_ENV = process.env.REACT_APP_BACKEND_ENVIRONMENT as
  | 'development'
  | 'staging'
  | 'production';
export const API_USERNAME = process.env.REACT_APP_API_USERNAME as string;
export const API_PASSWORD = process.env.REACT_APP_API_PASSWORD as string;
export const LINK_CLOSE_APP = 'rhksapp://closegame';
export const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY as string;

import storage from '@/utils/storage';

import { StageConfig } from '../features/sugoroku/types/index';

import StageConfigDevGourmet from './gourmet/develop/stages.json';
import StageConfigProductionGourmet from './gourmet/production/stages.json';
import StageConfigStagingGourmet from './gourmet/staging/stages.json';
import StageConfigDevTourism from './tourism/develop/stages.json';
import StageConfigProductionTourism from './tourism/production/stages.json';
import StageConfigStagingTourism from './tourism/staging/stages.json';

function getConfig(): StageConfig[] {
  let config: StageConfig[];
  switch (storage.getSkin()) {
    case 'tourism':
      switch (BACKEND_ENV) {
        case 'development':
          config = StageConfigDevTourism;
          break;
        case 'staging':
          config = StageConfigStagingTourism;
          break;
        case 'production':
          config = StageConfigProductionTourism;
      }
      break;
    default:
      switch (BACKEND_ENV) {
        case 'development':
          config = StageConfigDevGourmet;
          break;
        case 'staging':
          config = StageConfigStagingGourmet;
          break;
        case 'production':
          config = StageConfigProductionGourmet;
      }
  }
  return config;
}

export const SugorokuStageConfig = getConfig;
