import { useSkin } from './hooks';

export type MapImagesProps = {
  stageKey: string;
};

const MapImages = ({ stageKey }: MapImagesProps) => {
  const mapImages: JSX.Element[] = [];
  const skin = useSkin();
  const originalImageWidth = 14000;
  const originalImageHeight = 8000;
  const cropWidth = 1000;
  const cropHeight = 1000;
  const imageStyle = {
    width: `${cropWidth}px`,
    height: `${cropHeight}px`,
  };
  const divideX = originalImageWidth / cropWidth;
  const divideY = originalImageHeight / cropHeight;

  const getRowImages = (row: number) => {
    const images: JSX.Element[] = [];
    for (let i = 0; i < divideX; i++) {
      images.push(
        <img
          key={`line${i}`}
          data-src={`/map/${skin}/${stageKey}/${divideX * row + i}.png`}
          data-expand="1500"
          className="relative max-w-none lazyload bg-oceanBlue"
          style={imageStyle}
          alt=""
        />
      );
    }
    return images;
  };

  for (let i = 0; i < divideY; i++) {
    mapImages.push(
      <div className="flex" key={`row${i}`}>
        {getRowImages(i)}
      </div>
    );
  }

  return <>{mapImages}</>;
};

export default MapImages;
