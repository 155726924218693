import { useTranslation } from 'react-i18next';

import { SugorokuStageConfig } from '@/config';

import { useSkin } from '../sugoroku/hooks';

import { Badge } from './types';

export type MyBadgesProps = {
  badges: Badge[];
};

type BadgeConfig = {
  type: 'stage' | 'item';
  stageId?: number;
  got: boolean;
  key: string;
};

const MyBadges = ({ badges }: MyBadgesProps) => {
  const skin = useSkin();
  const { t } = useTranslation(['common', `badges/${skin}`]);

  const stageBadgeConfig: BadgeConfig[] = SugorokuStageConfig().map((x) => {
    return {
      type: 'stage',
      stageId: x.id,
      got: badges.find((badge) => badge.itemId == undefined && badge.stageId == x.id) != undefined,
      key: x.key,
    };
  });
  const itemBadgeConfig: BadgeConfig[] = SugorokuStageConfig()
    .map((x) => x.pieces)
    .flat()
    .filter((x) => x.isSpecial)
    .map((x, i) => {
      return {
        type: 'item',
        got: badges.filter((x) => x.itemId).length - i > 0,
        key: `item${x.index}`,
      };
    });

  const badgeConfig = stageBadgeConfig.concat(itemBadgeConfig).sort((a, b) => {
    if (a.got && !b.got) return -1;
    if (!a.got && b.got) return 1;
    return 0;
  });

  const imageName = (config: BadgeConfig) => {
    if (config.got) {
      switch (config.type) {
        case 'item':
          return 'item';
        case 'stage':
          return config.key;
      }
    } else {
      return 'unacquired';
    }
  };

  return (
    <>
      {badgeConfig.map((config) => (
        <div key={config.key} className="relative min-w-[85px] min-h-[129px]">
          <div className="relative">
            <img src={`badge/cell/${imageName(config)}.png`} alt="itemImage" />
          </div>
          <div className="absolute left-1 top-[80px] w-[80px]">
            <p className="flex w-full text-center items-center justify-center sm:fsz-14ptr md:fsz-16ptr whitespace-pre-line">
              {config.type == 'stage'
                ? t(`${config.key}.condition`, { ns: `badges/${skin}` })
                : t(`item.condition`, {
                    ns: `badges/${skin}`,
                    index: itemBadgeConfig.indexOf(config) + 1,
                  })}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default MyBadges;
