export function parseDates(data: any): any {
  const ISO_DATE_PATTERN = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/;
  const reviver = (key: string, value: any) => {
    if (typeof value === 'string' && ISO_DATE_PATTERN.test(value)) {
      return new Date(value);
    }
    return value;
  };
  return JSON.parse(data, reviver);
}
