// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Response } from '@/types';

import { Token } from '../types';

export type RefreshTokenDTO = {
  refreshToken: string;
};

export const refreshToken = ({
  refreshToken,
}: RefreshTokenDTO): Promise<Response<Token> | null> => {
  if (!refreshToken) {
    return new Promise((resolve) => {
      resolve(null);
    });
  }
  return axios.post(
    'game/users/refresh_token',
    {},
    {
      headers: {
        'X-REFRESH-TOKEN': refreshToken,
      },
    }
  );
};

type RefreshTokenQueryFnType = typeof refreshToken;

type RefreshTokenOptions = {
  token: string;
  config?: QueryConfig<RefreshTokenQueryFnType>;
};

export const useRefreshToken = ({ token, config }: RefreshTokenOptions) => {
  return useQuery<ExtractFnReturnType<RefreshTokenQueryFnType>>({
    queryKey: ['refreshToken', token],
    queryFn: () => refreshToken({ refreshToken: token }),
    ...config,
  });
};
