// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { Suspense } from 'react';
import { useRoutes, Outlet, RouteObject } from 'react-router-dom';

import { BACKEND_ENV } from '@/config';
import AddItem from '@/dev_only/alliance/AddItem';
import Authentication from '@/features/auth/components/Authentication';
import Unauthorized from '@/features/auth/components/Unauthorized';
import Top from '@/features/Top';

const App = () => {
  return (
    <Authentication errorFallback={<Unauthorized />}>
      <Suspense
        fallback={<div className="flex items-center justify-center w-screen h-screen">Loading</div>}
      >
        <Outlet />
      </Suspense>
    </Authentication>
  );
};

const routes = [
  {
    path: '/',
    element: <App />,
    children: [{ index: true, element: <Top /> }],
  },
] as RouteObject[];

if (BACKEND_ENV != 'production') {
  routes[0].children?.push({ path: 'add_item', element: <AddItem /> });
}

export const AppRoutes = () => {
  const element = useRoutes(routes);
  return <>{element}</>;
};
