// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { SkipItem } from '../skipItem/types';

type SkipItemButtonProps = {
  skipItems: SkipItem[];
  className: string;
  onClick?: () => void;
};

const SkipItemButton = ({ onClick, skipItems, className }: SkipItemButtonProps) => {
  const availableSkipItems = skipItems.filter((x) => !x.used);
  return (
    <div className={className}>
      <div className="relative" onClick={onClick}>
        <div className="absolute rounded-full border-white border-[0.18rem] w-[2.375rem] h-[2.375rem] text-white leading-[2.1rem] text-center font-bold text-xl bg-salmonPink right-0 -top-4">
          {availableSkipItems.length}
        </div>
        <img className="w-[4.5rem]" src="./skip_item_circle.png" alt="itemImage" />
      </div>
    </div>
  );
};

export default SkipItemButton;
