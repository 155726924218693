// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { Response } from '@/types';

import { Item } from '../types';

export type PatchItemConfirmDTO = {
  itemId: number;
};

export const patchItemConfirm = ({ itemId }: PatchItemConfirmDTO): Promise<Response<Item>> => {
  return axios.patch(`game/items/${itemId}/confirmed`);
};

type PatchItemConfirmOptions = {
  stageId: number;
  config?: MutationConfig<typeof patchItemConfirm>;
};

export const usePatchItemConfirmed = ({ stageId, config }: PatchItemConfirmOptions) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['items', stageId]);
    },
    ...config,
    mutationFn: patchItemConfirm,
  });
};
