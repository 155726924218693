// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig } from '@/lib/react-query';

export type AddEventDTO = {
  eventName: string;
  itemId?: number;
  taskId?: number;
  stageId?: number;
};

const getPlatform = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  if (/iphone|ipad|ipod/.test(userAgent)) {
    return 'ios';
  } else if (/android/.test(userAgent)) {
    return 'android';
  } else {
    return '';
  }
};

export const addEvent = (data: AddEventDTO) => {
  const postData: AddEventDTO = Object.assign(
    {
      appVersion: process.env.APP_VERSION,
      platform: getPlatform(),
    },
    data
  );
  return axios.post(`game/event_logs`, postData);
};

type AddEventOptions = {
  config?: MutationConfig<typeof addEvent>;
};

export const useAddEvent = ({ config }: AddEventOptions = {}) => {
  return useMutation({
    ...config,
    mutationFn: addEvent,
  });
};
