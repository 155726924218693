// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX
import clsx from 'clsx';
import React from 'react';

import SpinnerIcon from '@/components/Icons/Spinner';

const colorTypes = {
  primary:
    'bg-white text-black hover:bg-blur disabled:bg-blur border-button border-black  border-solid',
  gray: 'bg-grayButton text-white hover:bg-grayButton disabled:bg-grayButton border-button border-black  border-solid',
  orange:
    'bg-orange text-white !font-bold hover:bg-orange disabled:bg-orange rounded-[24px] border-buttonOrange border-black shadow-buttonPink border-[4px] border-black border-solid',
  pink: 'bg-pink text-white !font-bold hover:bg-pink disabled:bg-pink rounded-[24px] rounded-[24px] border-buttonOrange border-black shadow-buttonPink border-[4px] border-black  border-solid',
  blue: 'bg-blue text-white !font-bold hover:bg-blue disabled:bg-blue rounded-[24px] rounded-[24px] border-buttonOrange border-black shadow-buttonBlue border-[4px] border-black  border-solid',
  orangeNoBorder:
    'bg-tomatoRed text-white !font-bold hover:bg-tomatoRed disabled:bg-tomatoRed rounded-[8px]',
  primaryNoBorder: 'bg-white text-black rounded-[8px] border-[1px] border-[#D2D2D2]  border-solid',
};

const buttonSizes = {
  small: 'min-w-[7rem] h-[3rem] py-1 fsz-20ptr px-5',
  medium: 'min-w-[9.5rem] h-[3.5rem] py-1 fsz-24ptr px-6',
  large: 'min-w-[12rem] h-[4rem] py-1 md:fsz-28ptr fsz-24ptr px-8',
};

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  text: string;
  colorType?: keyof typeof colorTypes;
  buttonSize?: keyof typeof buttonSizes;
  loading?: boolean;
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      text,
      type = 'button',
      className = '',
      colorType = 'primary',
      buttonSize = 'medium',
      loading,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        data-cy={`button-${text}`}
        disabled={props.disabled || loading}
        className={clsx(
          'font-normal',
          colorTypes[colorType],
          buttonSizes[buttonSize],
          colorTypes[colorType],
          className
        )}
        {...props}
      >
        <div className={`flex justify-center items-center whitespace-nowrap`}>
          {loading && (
            <SpinnerIcon
              color={colorType === 'primary' ? 'white' : undefined}
              className={text !== '' ? `mr-2` : ''}
            />
          )}
          {text}
        </div>
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
