// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX
import clsx from 'clsx';
import { motion, AnimatePresence } from 'framer-motion';
import React from 'react';

const BorderColor = {
  gray: 'rounded-dialog border-solid border-dialog border-dialogBorderGray',
  blueLight: 'rounded-dialog border-solid border-dialog border-dialogBorderBlueLight',
  purpleLight: 'rounded-dialog border-solid border-dialog border-dialogBorderPurpleLight',
  blueShadow: 'rounded-dialog shadow-dialogBlue',
  nonBorder: 'rounded-none',
  roundBorder: 'rounded-[8px]',
};

const BackgroundColor = {
  white: 'bg-white',
  dialogBlue: 'bg-dialogBlue',
  blueLight: 'bg-blueLight',
  gradientSkyBlue: 'bg-gradientSkyBlue',
  gradientPurpleLight: 'bg-gradientPurpleLight',
};

const BackdropColor = {
  blur: 'bg-blur',
  opacity: '',
};

const Width = {
  full: 'h-full w-full',
  middle: 'h-auto w-[18.5rem]',
  auto: 'h-auto min-w-[20rem] max-w-[22rem]',
};

interface Props {
  children: React.ReactNode;
  nonRound?: boolean;
  padding?: string;
  isBackgroundClickable?: boolean;
  disableScroll?: boolean;
  openDialog: boolean;
  borderColor?: keyof typeof BorderColor;
  backgroundColor?: keyof typeof BackgroundColor;
  boxShadow?: boolean;
  styleDialog?: string;
  noPadding?: boolean;
  backdropColor?: keyof typeof BackdropColor;
  onClose?: () => void;
  width?: keyof typeof Width;
}

const Dialog: React.FC<Props> = ({
  children,
  nonRound,
  padding,
  disableScroll,
  openDialog,
  borderColor = 'gray',
  backgroundColor = 'white',
  boxShadow = false,
  styleDialog,
  backdropColor = 'blur',
  noPadding = false,
  onClose = () => {},
  width = 'auto',
}) => {
  React.useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  React.useEffect(() => {
    const doc = document.documentElement;
    function updateSize() {
      doc.style.setProperty('--window-height', `${window.innerHeight}px`);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => {
      window.removeEventListener('resize', updateSize);
    };
  }, []);

  const modal = {
    hidden: { y: '-100vh', opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { delay: 0.5 },
    },
  };

  const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  };

  return (
    <AnimatePresence onExitComplete={onClose}>
      {openDialog && (
        <motion.div
          variants={backdrop}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={clsx(
            'flex justify-center w-full full-wh left-0 items-center fixed z-1000003 top-0 bottom-0 h-full',
            BackdropColor[backdropColor]
          )}
        >
          <motion.div
            variants={modal}
            className={`m-auto flex flex-col md:mx-0 min-h-[6rem]
              ${BorderColor[borderColor]}
              ${nonRound && 'rounded-none'} ${padding || ''} mx-8 absolute md:self-center
              ${Width[width]}
              ${BackgroundColor[backgroundColor]}
              ${boxShadow ? 'shadow-special' : ''}
              ${styleDialog}
            `}
          >
            <div className={`${!disableScroll ? 'overflow-y' : 'overflow-y-hidden'} w-full h-full`}>
              <div className={`${noPadding ? 'p-0' : 'px-2 py-[2.5rem]'} flex flex-col h-full`}>
                {children}
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default Dialog;
