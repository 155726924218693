// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Response } from '@/types';

import { GoalAction } from '../types';

const getGoalAction = ({
  allianceId,
}: {
  allianceId: number;
}): Promise<Response<GoalAction | undefined>> => {
  return axios.get(`/game/alliance/goal_action`, {
    params: {
      allianceId,
    },
  });
};

type GetGoalActionQueryFnType = typeof getGoalAction;

type GetGoalActionOptions = {
  allianceId: number;
  config?: QueryConfig<GetGoalActionQueryFnType>;
};

export const useGoalAction = ({ allianceId, config }: GetGoalActionOptions) => {
  return useQuery<ExtractFnReturnType<GetGoalActionQueryFnType>>({
    queryKey: ['goal_action', allianceId],
    queryFn: () => getGoalAction({ allianceId }),
    ...config,
  });
};
