// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Response } from '@/types';

import { Badge } from '../types';

export const getBadges = (): Promise<Response<Badge[]>> => {
  return axios.get('game/badges');
};

type GetBadgesQueryFnType = typeof getBadges;

type GetBadgesOptions = {
  config?: QueryConfig<GetBadgesQueryFnType>;
};

export const useBadges = ({ config }: GetBadgesOptions = {}) => {
  return useQuery<ExtractFnReturnType<GetBadgesQueryFnType>>({
    queryKey: 'badges',
    queryFn: () => getBadges(),
    ...config,
  });
};
