// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import create from 'zustand';

type LoadingStore = {
  processes: Set<string>;
  addProcess: (id: string) => void;
  removeProcess: (id: string) => void;
};

export const useLoadingStore = create<LoadingStore>((set) => ({
  processes: new Set<string>(),
  addProcess: (id) => {
    set((state) => ({
      processes: state.processes.add(id),
    }));
  },
  removeProcess: (id) => {
    set((state) => {
      const shallow = new Set(state.processes);
      shallow.delete(id);
      return {
        processes: shallow,
      };
    });
  },
}));
