export type PieceProps = {
  isActive: boolean;
  piece: PieceConfig;
  onClick: (piece: PieceConfig) => void;
};

import { PieceConfig } from './types';

const Piece = ({ isActive, piece, onClick }: PieceProps) => {
  const imageName = (() => {
    if (piece.isGoal) {
      if (isActive) {
        if (piece?.tipsIndex) {
          return 'piece_goal_tip.png';
        }
        return 'piece_goal_active.png';
      } else {
        return 'piece_goal_inactive.png';
      }
    }
    if (piece.isSpecial) {
      if (isActive) {
        return 'piece_special_active.png';
      } else {
        return 'piece_special_inactive.png';
      }
    }
    if (isActive) {
      if (piece.tipsIndex) {
        return 'piece_normal_tip.png';
      }
      return 'piece_normal_active.png';
    } else {
      return 'piece_normal_inactive.png';
    }
  })();
  return (
    <div
      className="absolute w-32"
      style={{
        top: piece.top,
        left: piece.left,
      }}
      onClick={() => onClick(piece)}
    >
      <img
        className="absolute -translate-x-1/2 -translate-y-1/2"
        src={`./sugoroku/${imageName}`}
        alt="piece"
      />
    </div>
  );
};

export default Piece;
