// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Response } from '@/types';

import { SkipItem } from '../types';

const getSkipItems = (): Promise<Response<SkipItem[]>> => {
  return axios.get(`game/skip_items`);
};

type getSkipItemsQueryFnType = typeof getSkipItems;

type getSkipItemsOptions = {
  config?: QueryConfig<getSkipItemsQueryFnType>;
};

export const useSkipItem = ({ config }: getSkipItemsOptions = {}) => {
  return useQuery<ExtractFnReturnType<getSkipItemsQueryFnType>>({
    queryKey: ['skipItems'],
    queryFn: () => getSkipItems(),
    ...config,
  });
};
