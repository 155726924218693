// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Balloon from '@/components/Elements/Balloon';

const Bark = () => {
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const messages = [
    'character.dialog.general_1',
    'character.dialog.general_2',
    'character.dialog.general_3',
  ];
  useEffect(() => {
    const m = messages.at(Math.floor(Math.random() * messages.length));
    if (m) setMessage(m);
  }, []);

  return (
    <Balloon>
      <p className="p-4">{t(message)}</p>
    </Balloon>
  );
};

export default Bark;
