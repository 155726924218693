// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useEffect } from 'react';

type LockScreenProps = {
  open: boolean;
  unlock?: (value: boolean) => void;
  duration?: number;
};

const LockScreen = ({ open, unlock, duration = 0 }: LockScreenProps) => {
  useEffect(() => {
    if (open && unlock && duration > 0) {
      setTimeout(() => {
        unlock(false);
      }, duration);
    }
  }, [open, unlock, duration]);

  return <>{open && <div className="h-screen w-screen fixed z-1000004" />}</>;
};

export default LockScreen;
