// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import Axios, { AxiosRequestConfig } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

import { API_PASSWORD, API_URL, API_USERNAME } from '@/config';
import { useDisplayErrorStore } from '@/stores/displayError';
import { parseDates } from '@/utils/date';
import storage from '@/utils/storage';

function authRequestInterceptor(config: AxiosRequestConfig) {
  if (config.headers) {
    config.headers.Accept = 'application/json';
    config.headers['X-ACCESS-TOKEN'] = `${storage.getToken('access')}`;
    const skin = storage.getSkin();
    switch (skin) {
      case 'tourism':
        config.headers['X-GAME-KEY'] = process.env.REACT_APP_GAME_KEY_TOURISM;
        break;
      case 'gourmet':
        config.headers['X-GAME-KEY'] = process.env.REACT_APP_GAME_KEY_GOURMET;
        break;
    }
  }
  return config;
}

export const axios = Axios.create({
  baseURL: API_URL,
  auth: {
    username: API_USERNAME,
    password: API_PASSWORD,
  },
  transformResponse: [(data) => parseDates(data)],
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      storage.clearToken('access');
      storage.clearToken('refresh');
      history.pushState(null, '', '/?tokenexpired');
      return;
    }
    if (error.response.status === 404 || Math.floor(error.response.status / 100) === 5) {
      useDisplayErrorStore.getState().displayError();
      return;
    }
    return Promise.reject(error);
  }
);

applyCaseMiddleware(axios);
