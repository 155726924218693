// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX
import clsx from 'clsx';
import { t } from 'i18next';

import Button from '@/components/Elements/Button';

type GotoConsumeSkipItemProps = {
  className?: string;
  openConsume: () => void;
};

const GotoConsumeSkipItem = ({ className, openConsume }: GotoConsumeSkipItemProps) => {
  return (
    <div
      className={clsx(
        'bg-white bg-opacity-75 p-3 border-2 border-dialogBorderBlueLight border-opacity-25 rounded-md',
        className
      )}
    >
      <div className="flex">
        <div>
          <img className="w-12" src="/skip_item_circle.png" alt="skip item" />
        </div>
        <div className="whitespace-pre-wrap my-auto text-left px-2">
          {t('item.item_acquition_condition_popup.can_skip_with_ticket')}
        </div>
      </div>
      <Button
        className="mt-3 !fsz-16ptr"
        text={t('item.item_acquition_condition_popup.use_ticket')}
        onClick={openConsume}
        colorType="orange"
        buttonSize="small"
      ></Button>
    </div>
  );
};

export default GotoConsumeSkipItem;
