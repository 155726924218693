// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import clsx from 'clsx';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';

export type ToastProps = {
  colorType: keyof typeof colorTypes;
  text: string;
  duration?: number;
  onDismiss: () => void;
};

const colorTypes = {
  primary: 'bg-gray text-white',
  error: 'bg-red text-white',
};

const Toast = ({ colorType, text, duration = 3000, onDismiss }: ToastProps) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, duration);
  });

  return (
    <AnimatePresence onExitComplete={onDismiss}>
      {visible && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <div className={clsx('rounded-full bg-blur flex p-2 m-1', colorTypes[colorType])}>
            <p className="grow">{text}</p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Toast;
