import { animate, AnimationOptions, AnimationPlaybackControls } from 'framer-motion';

export type smoothScrollOptions = {
  top: number;
  left: number;
};

const smoothScroll = (
  container: HTMLElement,
  { top, left }: smoothScrollOptions,
  transition: AnimationOptions<number> = {}
): Promise<AnimationPlaybackControls[]> => {
  const startLeft = container.scrollLeft;
  const startTop = container.scrollTop;

  const setLeft = animate(startLeft, left, {
    ...transition,
    onUpdate: (x) => {
      container.scrollLeft = x;
    },
  });
  const setTop = animate(startTop, top, {
    ...transition,
    onUpdate: (x) => {
      container.scrollTop = x;
    },
  });

  return Promise.all([setLeft, setTop]);
};

export default smoothScroll;
