// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';

import { useSkin } from '../sugoroku/hooks';
import { PieceConfig } from '../sugoroku/types';

export type GetPiecePopupProp = {
  pieceConfig: PieceConfig;
  onClose: () => void;
};

const GetPiecePopup = ({ pieceConfig, onClose }: GetPiecePopupProp) => {
  const skin = useSkin();
  const { t } = useTranslation(['common', `pieces/${skin}`]);
  const [openDialog, setOpenDialog] = useState(true);

  return (
    <>
      <Dialog
        openDialog={openDialog}
        borderColor={pieceConfig.isSpecial ? 'purpleLight' : 'blueLight'}
        backgroundColor={pieceConfig.isSpecial ? 'gradientPurpleLight' : 'gradientSkyBlue'}
        onClose={onClose}
      >
        <div className="flex flex-col items-center">
          <div className="relative max-w-[220px]">
            <img src="./get_frame_label.png" className="w-[100%] h-auto" alt="get frame" />
            <p className="absolute fsz-24ptr font-bold top-[5px] left-0 right-0 m-auto max-w-[150px] text-center">
              {t('item.get_frame.get_title_number')}
            </p>
          </div>
          <div className="flex flex-col max-h-[calc(78vh-200px)] overflow-y-auto">
            <div
              className={`${
                pieceConfig.isSpecial
                  ? "bg-[url('/public/tip_bg_purple.svg')]"
                  : "bg-[url('/public/tip_bg.svg')]"
              } w-[100%] min-h-[269px] bg-no-repeat bg-center flex flex-col items-center`}
            >
              <p className="fsz-32ptr font-black max-w-[15.75rem] leading-9 tracking-tighter break-words text-center">
                {t(`${pieceConfig.index}.city`, { ns: `pieces/${skin}` })}
              </p>
              <p className="fsz-16ptr">{t('item.get_frame.get_title')}</p>
              <div className="max-w-[200px] mt-3">
                <img
                  src="./get_frame_travel_place.svg"
                  className="w-[100%] h-auto"
                  alt="itemImageDemo"
                />
              </div>
            </div>

            <p className="fsz-16ptr md:mx-[45px] mx-[15px] text-center whitespace-pre-wrap">
              {t('item.get_frame.get_title_desc')}
            </p>
          </div>
          <Button
            className="md:mt-10 md:mx-[60px] mt-2 mx-[10px]"
            text={t('common.close')}
            colorType={'pink'}
            buttonSize={'large'}
            onClick={() => {
              setOpenDialog(false);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};
export default GetPiecePopup;
