// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import Notifications from '@/components/Elements/Notifications';
import { queryClient } from '@/lib/react-query';

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={<div className="flex items-center justify-center w-screen h-screen">Loading</div>}
    >
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
          <Router>{children}</Router>
        </QueryClientProvider>
        <Notifications />
      </HelmetProvider>
    </React.Suspense>
  );
};
