import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';
import { SugorokuStageConfig } from '@/config';

import { useSkin } from '../sugoroku/hooks';
import { StageConfig } from '../sugoroku/types';

type GoalIntroductionPopupProps = {
  onClose?: () => void;
  stageConfig: StageConfig;
};

const GoalIntroductionPopup = ({ stageConfig, onClose = () => {} }: GoalIntroductionPopupProps) => {
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();
  const isOnboarding = stageConfig.id == SugorokuStageConfig()[0].id;
  const skin = useSkin();

  return (
    <Dialog
      openDialog={open}
      onClose={onClose}
      borderColor="blueShadow"
      backgroundColor="gradientSkyBlue"
      disableScroll={true}
    >
      <div className="w-full flex flex-col items-center">
        <p className="text-center font-bold">
          {isOnboarding
            ? t('stage.goal_introduction.first_asia')
            : t('stage.goal_introduction.next_goal')}
        </p>
        <div className="mx-auto my-2 bg-mapDialog shadow-innerLg rounded-lg w-full max-w-[17.375rem] p-1">
          <img
            className="mx-auto mt-1 h-8"
            src={`goal_introduction_text/${skin}/${stageConfig.key}.png`}
            alt="goal"
          />
        </div>
        <p className="text-center font-bold">{t('stage.goal_introduction.lets_aim_for')}</p>
        <div className="relative mx-auto rounded-lg overflow-hidden mt-3 w-full max-w-[17.375rem]">
          <img src={`/goal_introduction_map/${skin}/${stageConfig.key}.png`} alt="turorial_map" />
        </div>
        <div className="flex flex-row gap-2 items-center bg-blackGray justify-center rounded-3xl text-white px-4 mt-4">
          <p className="fsz-20ptr">{t('stage.goal_introduction.remain_to_goal')}</p>
          <p className="flex flex-row items-center fsz-20ptr">
            <span className="fsz-36ptr font-bold">
              {stageConfig.pieces.filter((x) => !x.isSpecial).length - (isOnboarding ? 1 : 0)}
            </span>
            {t('stage.goal_introduction.remain_piece')}
          </p>
        </div>
        <Button
          className="max-w-[16.3125rem] w-full mx-auto mt-4 mb-2 block"
          text={t('stage.goal_introduction.start_game')}
          colorType="orange"
          buttonSize="medium"
          onClick={() => {
            setOpen(false);
          }}
        />
      </div>
    </Dialog>
  );
};

export default GoalIntroductionPopup;
