// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';

import { useSkin } from '../sugoroku/hooks';

export type HowToPlayPopupProps = {
  open: boolean;
  onClose: () => void;
};

const HowToPlayPopup = ({ open, onClose }: HowToPlayPopupProps) => {
  const { t } = useTranslation();
  const skin = useSkin();
  const [openItem, setOpenItem] = useState(false);

  return (
    <Dialog
      openDialog={open}
      borderColor={'nonBorder'}
      backgroundColor="gradientSkyBlue"
      styleDialog="!rounded-dialog"
    >
      <div className="flex flex-col items-center justify-center">
        {openItem ? (
          <div className="w-full flex flex-col items-center overflow-y-auto">
            <div className="flex items-center h-full bg-blackGray rounded-[1.25rem] mb-5 py-1 w-[220px] justify-center">
              <p className="fsz-20ptr text-white text-center w-[12.5rem]">
                {t('sugoroku.intro.second_slide.title')}
              </p>
            </div>
            <div className="max-w-[17.25rem]">
              <img
                src={`./howtoplay_main_image/${skin}.png`}
                className="w-full h-auto"
                alt="intro1"
              />
            </div>
            <p className="mt-4 text-black whitespace-pre-wrap text-center w-[15.625rem]">
              {t(`sugoroku.intro.second_slide.desc_${skin}`)}
            </p>
          </div>
        ) : (
          <div className="w-full flex flex-col items-center overflow-y-auto">
            <div className="flex items-center h-full bg-blackGray rounded-[1.25rem] mb-5 py-1 w-[13.75rem] justify-center">
              <p className="fsz-20ptr text-white text-center w-[12.5rem]">
                {t('sugoroku.intro.first_slide.title')}
              </p>
            </div>
            <div className="flex flex-col items-center overflow-y-auto">
              <div className="bg-white shadow-surogokuIntro rounded-[1.25rem] p-4 flex">
                <div className="max-w-[4.5rem]">
                  <img src="./sugoroku_intro_1.png" className="w-full h-auto" alt="intro1" />
                </div>
                <div className="ml-4 flex flex-col  justify-center">
                  <p className="fsz-18ptr font-bold text-dialogBorderGray">
                    {t('sugoroku.intro.first_slide.text_desc_1')}
                  </p>
                  <p className="fsz-16ptr text-dialogBorderGray">
                    {t('sugoroku.intro.first_slide.text_desc_2')}
                  </p>
                </div>
              </div>
              <div className="max-w-[28px] my-2">
                <img src="./sugoroku_intro_equal.svg" className="w-full h-auto" alt="intro1" />
              </div>
              <div className="bg-white shadow-surogokuIntro rounded-[1.25rem] p-4 flex">
                <div className="max-w-[4.5rem]">
                  <img src="./sugoroku_intro_2.png" className="w-full h-auto" alt="intro1" />
                </div>
                <div className="ml-2 flex flex-col  justify-center">
                  <p className="fsz-18ptr font-bold text-dialogBorderGray">
                    {t('sugoroku.intro.first_slide.text_desc_3')}
                  </p>
                  <p className="fsz-16ptr text-dialogBorderGray">
                    {t('sugoroku.intro.first_slide.text_desc_4')}
                  </p>
                </div>
              </div>
              <p className="mt-4 font-bold text-black whitespace-pre-wrap text-center w-[15.625rem]">
                {t(`sugoroku.intro.first_slide.desc`)}
              </p>
            </div>
          </div>
        )}

        <Button
          className="mt-6 md:mx-[3.75rem] smLarge:mx-auto"
          text={t('common.next')}
          colorType={'pink'}
          buttonSize={'medium'}
          onClick={() => {
            if (openItem) {
              onClose();
              setOpenItem(false);
            } else {
              setOpenItem(true);
            }
          }}
        />
      </div>
    </Dialog>
  );
};

export default HowToPlayPopup;
