// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { Response } from '@/types';

import { SkipItem } from '../types';

export type PatchSkipItemConfirmDTO = {
  skipItemId: number;
};

export const PatchSkipItemConfirm = ({
  skipItemId,
}: PatchSkipItemConfirmDTO): Promise<Response<SkipItem>> => {
  return axios.patch(`game/skip_items/${skipItemId}/confirmed`);
};

type PatchSkipItemConfirmOptions = {
  config?: MutationConfig<typeof PatchSkipItemConfirm>;
};

export const usePatchSkipItemConfirmed = ({ config }: PatchSkipItemConfirmOptions = {}) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['skipItems']);
    },
    ...config,
    mutationFn: PatchSkipItemConfirm,
  });
};
