// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';

export type GameOnboardingPopupProps = {
  onClose?: () => void;
};

const GameOnboardingPopup = ({ onClose = () => {} }: GameOnboardingPopupProps) => {
  const [openDialog, setOpenDialog] = useState(true);
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        openDialog={openDialog}
        width={'full'}
        nonRound
        borderColor="nonBorder"
        backgroundColor="blueLight"
        onClose={onClose}
      >
        <div className="flex flex-col items-center justify-center gap-4 h-full px-6">
          <img className="" src="./game_onboarding_title.png" alt="game_character" />
          <div className="flex items-center justify-center flex-col relative mt-[6.5rem]">
            <div className="absolute -top-[6.5rem] -right-[1.75rem]">
              <div className="top-0 w-[11.25rem] ">
                <img
                  src="/onboarding_text_area.svg"
                  className="w-[100%] h-auto"
                  alt="cat-text"
                ></img>
              </div>

              <p className="absolute top-4 whitespace-pre-wrap text-center fsz-18ptr w-[11.25rem] left-0 right-0 m-auto">
                {t('stage.onboarding.talk_paragraph')}
              </p>
            </div>
            <img src="/hug_cat.svg" className="w-[130px]" alt="cat"></img>
          </div>
          <Button
            className="mt-7 w-[90%] py-3 h-[4rem]"
            text={t('stage.onboarding.challenge')}
            buttonSize={'small'}
            colorType="orangeNoBorder"
            onClick={() => {
              setOpenDialog(!openDialog);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default GameOnboardingPopup;
