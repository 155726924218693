// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Response } from '@/types';

import { Stage } from '../types';

export const getActiveStage = (): Promise<Response<Stage | undefined>> => {
  return axios.get('game/stages/selecting_stage');
};

type GetActiveStageQueryFnType = typeof getActiveStage;

type GetActiveStageOptions = {
  config?: QueryConfig<GetActiveStageQueryFnType>;
};

export const useActiveStage = ({ config }: GetActiveStageOptions = {}) => {
  return useQuery<ExtractFnReturnType<GetActiveStageQueryFnType>>({
    queryKey: ['activeStage'],
    queryFn: () => getActiveStage(),
    ...config,
  });
};
