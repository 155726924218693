import { useTranslation } from 'react-i18next';

export type RemainingCounterProps = {
  confirmed: number;
  remain: number;
};

const RemainingCounter = ({ confirmed, remain }: RemainingCounterProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="flex flex-row gap-4 p-4 bg-white
          bg-gradient-to-b from-blue to-lightBlue 
          border-4 border-dialogBorderBlueLight rounded-[1.75rem]
          shadow-[4px_4px_0px_2px_rgba(17,191,208,0.25)]"
    >
      <div className="flex flex-col items-center bg-blackGray rounded-2xl fsz-18ptr text-white px-5 py-1">
        <p className="">{t('sugoroku.remaining_counter.progress')}</p>
        <p className="font-bold leading-6">
          <span className="fsz-36px">{confirmed}</span>
          {t('sugoroku.remaining_counter.piece')}
        </p>
      </div>
      {remain > 0 && (
        <div className="flex flex-col items-center bg-blackGray rounded-2xl fsz-18ptr text-white px-5 py-1">
          <p className="">{t('sugoroku.remaining_counter.remaining_to_goal')}</p>
          <p className="font-bold leading-6">
            <span className="fsz-36px">{remain}</span>
            {t('sugoroku.remaining_counter.piece')}
          </p>
        </div>
      )}
    </div>
  );
};

export default RemainingCounter;
