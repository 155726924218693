// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX
import { useTranslation } from 'react-i18next';

const ErrorScreen = () => {
  const { t } = useTranslation();

  return (
    <div className="h-screen w-screen bg-[#D9F0F5] absolute z-100">
      <div className="text-center mt-28 fsz-28ptr font-bold whitespace-pre-wrap">
        {t('error.error_occurred')}
      </div>
      <div className="mt-5 mx-auto w-52 text-center fsz-16ptr">{t('error.access_later')}</div>
      <img className="mx-auto mt-11" src="/shadow_cat.png" alt="cat" />
    </div>
  );
};

export default ErrorScreen;
