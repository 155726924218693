// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { nanoid } from 'nanoid';
import create from 'zustand';

export type Notification = {
  id: string;
  message: string;
  type: NotificationType;
};

export type NotificationType = 'info' | 'error' | 'success';

type NotificationStore = {
  notifications: Notification[];
  addNotification: (notification: Omit<Notification, 'id'>) => void;
  dismissNotification: (id: string) => void;
};

export const useNotificationStore = create<NotificationStore>((set) => ({
  notifications: [],
  addNotification: (notification) =>
    set((state) => ({
      notifications: [...state.notifications, { id: nanoid(), ...notification }],
    })),
  dismissNotification: (id) => {
    set((state) => ({
      notifications: state.notifications.filter((x) => x.id !== id),
    }));
  },
}));
