// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Response } from '@/types';

import { UserNotification } from '../types';

const getUserNotification = (): Promise<Response<UserNotification[]>> => {
  return axios.get(`game/user_notifications`);
};

type GetUserNotificationQueryFnType = typeof getUserNotification;

type GetUserNotificationOptions = {
  config?: QueryConfig<GetUserNotificationQueryFnType>;
};

export const useUserNotification = ({ config }: GetUserNotificationOptions = {}) => {
  return useQuery<ExtractFnReturnType<GetUserNotificationQueryFnType>>({
    queryKey: ['userNotifications'],
    queryFn: () => getUserNotification(),
    ...config,
  });
};
