import { motion, useAnimationControls } from 'framer-motion';
import React, { useImperativeHandle, useState } from 'react';

import Bark from '../character/Bark';

import { ICharacterAnimator } from './types';

type CharacterProps = {
  initialPosition: {
    top: number;
    left: number;
  };
};

const Character = React.forwardRef<ICharacterAnimator, CharacterProps>(function Character(
  { initialPosition }: CharacterProps,
  ref
) {
  const [moving, setMoving] = useState(false);
  const animationControls = useAnimationControls();

  const animate = async (top: number, left: number) => {
    await animationControls.start({
      opacity: 0,
    });
    setMoving(true);
    animationControls.start({
      opacity: 1,
    });
    await animationControls.start({
      transition: {
        type: 'spring',
        stiffness: 30,
        damping: 2,
        mass: 1,
      },
      scale: [1.2, 1],
    });
    await animationControls.start({
      transition: {
        duration: 1.2,
        ease: 'easeInOut',
      },
      top: top,
      left: left,
    });
    await animationControls.start({
      opacity: 0,
    });
    setMoving(false);
    await animationControls.start({
      opacity: 1,
    });
  };

  useImperativeHandle(ref, () => ({ animate: (top: number, left: number) => animate(top, left) }));

  return (
    <motion.div
      className="absolute w-40"
      animate={animationControls}
      style={{ top: initialPosition.top, left: initialPosition.left }}
    >
      {moving ? (
        <img
          className="absolute"
          src={`./sugoroku/character_moving.svg`}
          alt="character_moving"
          style={{
            translate: '-50% -50%',
          }}
        />
      ) : (
        <>
          <div
            className="absolute flex flex-col items-center gap-2"
            style={{
              translate: '-50% -110%',
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: {
                  delay: 1,
                },
              }}
              exit={{ opacity: 0 }}
            >
              <Bark></Bark>
            </motion.div>
            <img
              src={`./sugoroku/character_stop.svg`}
              alt="character_stop"
              style={{
                translate: '0%',
              }}
            />
          </div>
        </>
      )}
    </motion.div>
  );
});

export default Character;
