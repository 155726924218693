// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Response } from '@/types';

import { OnboardingCompleted } from '../types';

const getOnBoardingCompleted = (): Promise<Response<OnboardingCompleted>> => {
  return axios.get(`game/onboarding_completed`);
};

type GetOnBoardingCompletedQueryFnType = typeof getOnBoardingCompleted;

type GetOnBoardingCompletedOptions = {
  config?: QueryConfig<GetOnBoardingCompletedQueryFnType>;
};

export const useOnBoardingCompleted = ({ config }: GetOnBoardingCompletedOptions = {}) => {
  return useQuery<ExtractFnReturnType<GetOnBoardingCompletedQueryFnType>>({
    queryKey: ['onBoardingCompleted'],
    queryFn: () => getOnBoardingCompleted(),
    ...config,
  });
};
