// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';

import { GoalAction } from './types';

export type GoalActionPopupProps = {
  goalAction: GoalAction;
  onClose?: () => void;
};

const GoalActionPopup = ({ goalAction, onClose = () => {} }: GoalActionPopupProps) => {
  const [openDialog, setOpenDialog] = useState(true);
  const { t } = useTranslation();

  return (
    <Dialog
      openDialog={openDialog}
      borderColor="roundBorder"
      backgroundColor="white"
      backdropColor="opacity"
      noPadding
    >
      <img className="" src="./bg_goal.svg" alt="bg_goal" />
      <div className="flex flex-col">
        <div className="flex flex-col items-center mt-6">
          <p className="fsz-18ptr font-bold">{t('alliance.goal_action_popup.title')}</p>
          <p className="fsz-16ptr text-center  whitespace-pre-wrap mt-3">
            {t('alliance.goal_action_popup.description')}
          </p>
        </div>
        <div className="flex flex-col items-center text-center">
          <Button
            className="mt-7 w-[90%] py-3 h-[4rem]"
            text={t('alliance.goal_action_popup.act')}
            buttonSize={'small'}
            colorType="orangeNoBorder"
            onClick={() => {
              window.location.href = goalAction.url;
            }}
          />
          <Button
            className="mt-4 mb-6 w-[90%] py-3 h-[4rem]"
            buttonSize={'small'}
            colorType="primaryNoBorder"
            text={t('common.back')}
            onClick={() => {
              onClose();
              setOpenDialog(false);
            }}
          />
        </div>
      </div>
    </Dialog>
  );
};

export default GoalActionPopup;
