// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX
import { motion } from 'framer-motion';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line no-restricted-imports
import { Item } from '@/features/item/types';

export type AchievementLogItemProps = {
  item: Item;
  index: number;
  itemOutDisplayItemFirstTime: number;
  initialDelay: number;
  itemDelay: number;
};

const AchievementLogItem: React.FC<AchievementLogItemProps> = ({
  item,
  index,
  itemOutDisplayItemFirstTime,
  initialDelay,
  itemDelay,
}: AchievementLogItemProps) => {
  const { t } = useTranslation();
  const refItem = useRef<HTMLDivElement>(null);

  setTimeout(() => {
    refItem.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, (initialDelay + index * itemDelay) * 1000);

  return (
    <motion.div
      className="relative bg-white mx-2 flex justify-between items-center rounded-[8px] mb-3 border-itemLogBorder border-solid border-[2px]"
      key={item.id}
      initial={{ opacity: 0, translateY: index > 0 ? 50 : 0 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ ease: 'easeIn', duration: 0.3, delay: 1 + index * 0.6 }}
      ref={refItem}
    >
      <div className="absolute z-10 top-[1px]">
        <p className="absolute whitespace-pre-wrap text-left font-bold text-itemLogLabelColor fsz-14ptr top-[2px] left-5 text-bg-gradient-to-b text-from-[#ECF6F8] text-to-[#D9F0F5]">
          {t('item.achievement_log_popup.index', { n: index + 1 })}
        </p>
        <img src="./achievement_item_label.svg" alt="label"></img>
      </div>
      <motion.div
        className="absolute z-10 right-0"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, scale: [1.5, 1] }}
        transition={{
          ease: 'easeIn',
          duration: 0.5,
          // 0.5 is delay transition
          delay: itemOutDisplayItemFirstTime + 0.5 + index * itemDelay,
        }}
      >
        <div className="w-[72px] h-[72px] mr-4">
          <img src="./got.svg" alt="got"></img>
        </div>
      </motion.div>
      <div className="relative mt-8 mb-4">
        <p className="whitespace-pre-wrap text-left text-blackBold ml-3 fsz-14ptr">{`${item.achievementDetail}`}</p>
      </div>
    </motion.div>
  );
};
export default AchievementLogItem;
