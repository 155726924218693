// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';
import Ribbon from '@/components/Elements/Ribbon';

import { SkipItem } from '../skipItem/types';

import MySkipItems from './MySkipItems';

export type MySkipItemPopupProps = {
  skipItems: SkipItem[];
  open: boolean;
  onClose: () => void;
};

const MySkipItemPopup = ({ skipItems, open, onClose }: MySkipItemPopupProps) => {
  const availableSkipItems = skipItems.filter((x) => !x.used);
  const { t } = useTranslation();

  return (
    <>
      <Dialog
        width="middle"
        openDialog={open}
        borderColor="blueLight"
        backgroundColor="gradientSkyBlue"
      >
        <Ribbon title={t('skip_item.my_skip_item_popup.title')} type="leftBlue" />
        <div className="flex justify-center items-center h-full bg-blackGray mx-auto px-3 rounded-[1.25rem] py-1 smLarge:py-0">
          <p className="fsz-20ptr text-white font-bold">
            {t('skip_item.my_skip_item_popup.available_count', {
              count: availableSkipItems.length,
            })}
          </p>
        </div>
        <div className="mt-5 flex flex-col smLarge:max-h-[18.75rem] overflow-y-auto scrollbar scrollbar-very-thin scrollbar-thumb-rounded scrollbar-track-rounded scrollbar-track-mintGreen scrollbar-thumb-skyBlue">
          <div className="mx-2 grid grid-cols-3 gap-4 md:max-h-[18.375rem] smLarge:max-h-[13rem] justify-items-center">
            <MySkipItems skipItems={skipItems} />
          </div>
        </div>
        <p className="text-center tracking-wide">
          {t('skip_item.my_skip_item_popup.remain')}
          <span className="font-bold fsz-40ptr text-paleBlue">{availableSkipItems.length}</span>
          {t('skip_item.my_skip_item_popup.can_be_used')}
        </p>
        <p className="text-center whitespace-pre-wrap tracking-wide mt-4 smLarge:mt-2">
          {t('skip_item.my_skip_item_popup.how_to_use')}
        </p>

        <Button
          className="flex md:mt-10 mx-[1.25rem] mt-5 justify-center"
          text={t('common.close')}
          colorType={'pink'}
          buttonSize={'large'}
          onClick={() => {
            onClose();
          }}
        />
      </Dialog>
    </>
  );
};

export default MySkipItemPopup;
