// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';

import { usePatchNotificationConfirmed } from './api/patchNotificationConfirm';
import { UserNotification } from './types';

type NotificationPopupProps = {
  userNotification: UserNotification;
};

const NotificationPopup = ({ userNotification }: NotificationPopupProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const patchNotification = usePatchNotificationConfirmed();

  return (
    <Dialog
      openDialog={open}
      borderColor={'nonBorder'}
      backgroundColor="gradientSkyBlue"
      styleDialog="!rounded-dialog"
      noPadding
    >
      <div className="flex flex-col items-center justify-center p-6">
        <div className="flex items-center h-full bg-blackGray rounded-[1.25rem] py-1 w-[220px] justify-center mb-6">
          <p className="fsz-20ptr text-white text-center w-[12.5rem]">
            {t('stage.notification.title')}
          </p>
        </div>
        <div className="w-[100%] flex flex-col items-center smLarge:min-h-[14.31rem] sm:max-h-[12.5rem] smLarge:max-h-[20.5rem] md:min-h-[22.5rem] overflow-y-auto">
          <p className="fsz-20ptr font-bold text-center whitespace-pre-wrap">
            {userNotification.notification.title}
          </p>
          <p className="fsz-16ptr text-center whitespace-pre-wrap mt-2 mb-4">
            {userNotification.notification.description}
          </p>
          <div className="w-[17.5rem] h-[17.5rem] flex items-center justify-center overflow-y-scroll overflow-x-scroll">
            <img
              src={userNotification.notification.imageUrl}
              className="w-[100%] h-auto"
              alt="notify-content"
            />
          </div>
          <p className="fsz-16ptr text-center whitespace-pre-wrap mt-4">
            {userNotification.notification.supplement}
          </p>
        </div>

        <Button
          className="mt-6 md:mx-[3.75rem] smLarge:mx-auto"
          text={t('common.close')}
          colorType={'pink'}
          buttonSize={'medium'}
          onClick={() => {
            setOpen(false);
            patchNotification.mutateAsync({ notificationId: userNotification.id });
          }}
        />
      </div>
    </Dialog>
  );
};

export default NotificationPopup;
