import { t } from 'i18next';

import { SkipItem } from './types';

export type MySkipItemProps = {
  skipItems: SkipItem[];
};

const MySkipItems = ({ skipItems }: MySkipItemProps) => {
  return (
    <>
      {skipItems.map((skipItem, index) => (
        <div
          key={skipItem.id}
          data-testid="skip-item"
          className="relative min-w-[85px] min-h-[129px]"
        >
          <div className="relative">
            <img src="./skip_item.png" alt="itemImage" />
            {skipItem.used && (
              <div
                data-testid="used"
                className="absolute left-[3px] top-[3px] w-[85px] h-[129px] bg-blackBlur rounded-[6px]"
              >
                <div className="flex w-full h-full items-center justify-center">
                  <img src="./item_used.svg" alt="itemImage" />
                </div>
              </div>
            )}
          </div>
          <div className="absolute left-1 top-[70px] w-[80px]">
            <p className="flex w-full text-center items-center justify-center sm:fsz-14ptr md:fsz-16ptr whitespace-pre-line">
              {t('skip_item.my_skip_item.task_count', {
                count: (index + 1) * skipItem.gameSkipItem.numberOfTaskTime,
              })}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};

export default MySkipItems;
