// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';
import Ribbon from '@/components/Elements/Ribbon';
import { SugorokuStageConfig } from '@/config';

import { useSkin } from '../sugoroku/hooks';
import { PieceConfig } from '../sugoroku/types';

import { Badge } from './types';

export type BadgeAcquisitionPopupProps = {
  onClose?: () => void;
  badge: Badge;
  totalPieceCount?: number;
  piece: PieceConfig;
};

const BadgeAcquisitionPopup = ({
  badge,
  piece,
  totalPieceCount = undefined,
  onClose = () => {},
}: BadgeAcquisitionPopupProps) => {
  const skin = useSkin();
  const { t } = useTranslation(['common', `pieces/${skin}`, `tips/${skin}`, `badges/${skin}`]);
  const [openDialog, setOpenDialog] = useState(true);
  const isItemBadge = badge.itemId != undefined;
  const stageConfig = SugorokuStageConfig().find((x) => x.id == badge.stageId);

  return (
    <>
      <Dialog
        width="middle"
        openDialog={openDialog}
        borderColor={isItemBadge ? 'purpleLight' : 'blueLight'}
        backgroundColor={isItemBadge ? 'gradientPurpleLight' : 'gradientSkyBlue'}
        onClose={onClose}
      >
        <Ribbon
          type={isItemBadge ? 'centerPurple' : 'centerBlue'}
          title={t('badge.badge_acquition_popup.title')}
        />
        <div className="flex flex-col items-center justify-center mt-2 sm:max-h-[25rem] smLarge:max-h-[35rem] overflow-y-scroll">
          <div
            className={clsx(
              isItemBadge
                ? "bg-[url('/public/tip_bg_purple.svg')]"
                : "bg-[url('/public/tip_bg.svg')]",
              'min-w-[269px] bg-no-repeat bg-center flex flex-col items-center'
            )}
          >
            <p className="fsz-32ptr font-bold mt-1">
              {t(`${isItemBadge ? 'item' : stageConfig!.key}.title`, { ns: `badges/${skin}` })}
            </p>
            <div className="max-w-[135px] mt-3">
              <img
                src={`/badge/${isItemBadge ? 'item' : stageConfig!.key}.svg`}
                className="w-[100%] h-auto"
                alt="itemImageDemo"
              />
            </div>
          </div>

          <p className="fsz-20ptr mt-2 mb-1 text-center">{t('badge.badge_acquition_popup.goal')}</p>
          <p className="bg-blackGray py-4 fsz-20ptr text-white w-[240px] font-bold rounded-[24px] text-center">
            {t(`${piece.index}.city`, { ns: `pieces/${skin}` })}
          </p>

          {totalPieceCount && (
            <>
              <p className="fsz-20ptr mt-2 mb-1 text-center">
                {t('badge.badge_acquition_popup.finishedKoma')}
              </p>
              <p className="bg-blackGray py-4 fsz-20ptr text-white w-[240px] font-bold rounded-[24px] text-center">
                {t('badge.badge_acquition_popup.finishedKomaCount', {
                  count: totalPieceCount,
                })}
              </p>
            </>
          )}

          <Button
            className="my-6 mx-[60px]"
            text={t('common.close')}
            colorType={'pink'}
            buttonSize={'medium'}
            onClick={() => {
              setOpenDialog(false);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default BadgeAcquisitionPopup;
