// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Response } from '@/types';

import { Alliance } from '../types';

const getAlliance = (): Promise<Response<Alliance>> => {
  return axios.get(`/game/alliance`);
};

type GetAllianceQueryFnType = typeof getAlliance;

type GetAllianceOptions = {
  config?: QueryConfig<GetAllianceQueryFnType>;
};

export const useAlliance = ({ config }: GetAllianceOptions = {}) => {
  return useQuery<ExtractFnReturnType<GetAllianceQueryFnType>>({
    queryKey: ['Alliance'],
    queryFn: () => getAlliance(),
    ...config,
  });
};
