// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { motion } from 'framer-motion';
import i18next from 'i18next';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { SugorokuStageConfig } from '@/config';
import { useDisplayErrorStore } from '@/stores/displayError';
import { useLoadingStore } from '@/stores/loading';

import Loader from '../components/Elements/Loader';

import ErrorScreen from './errorScreen/ErrorScreen';
import { useItems } from './item/api/getItems';
import { useOnBoardingCompleted } from './onboarding/api/getOnboardingCompleted';
import Onboarding from './onboarding/Onboarding';
import { useOnboardingStatusStore } from './onboarding/stores/OnboardingStatusStore';
import { useActiveStage } from './stage/api/getActiveStage';
import { useSelectStage } from './stage/api/selectStage';
import { useSkin } from './sugoroku/hooks';
import Sugoroku from './sugoroku/Sugoroku';

const Top = () => {
  const getActiveStage = useActiveStage();
  const getItems = useItems({
    stageId: getActiveStage.data?.data?.id ?? -1,
    config: { enabled: getActiveStage.data?.data?.id != undefined },
  });
  const getOnboardingCompleted = useOnBoardingCompleted({
    config: { enabled: getActiveStage.data?.data?.id != undefined },
  });
  const selectStage = useSelectStage();
  const { currentStep: onboardingStep } = useOnboardingStatusStore();
  const { isDisplayError } = useDisplayErrorStore();
  const { addProcess: addLoadingProcess, removeProcess: removeLoadingProcess } = useLoadingStore();
  const loadingProcessId = 'top';
  const skin = useSkin();
  const { t } = useTranslation();

  useEffect(() => {
    if (getActiveStage.data == undefined) return;
    if (getActiveStage.data?.data == undefined) {
      selectStage.mutateAsync({ stageId: SugorokuStageConfig()[0].id });
    }
  }, [getActiveStage.data]);

  useEffect(() => {
    if (
      getActiveStage.isLoading ||
      getItems.isLoading ||
      getOnboardingCompleted.isLoading ||
      selectStage.isLoading
    ) {
      addLoadingProcess(loadingProcessId);
    } else {
      removeLoadingProcess(loadingProcessId);
    }
  }, [
    getActiveStage.isLoading,
    getItems.isLoading,
    getOnboardingCompleted.isLoading,
    selectStage.isLoading,
  ]);

  useEffect(() => {
    i18next.loadNamespaces([`badges/${skin}`, `pieces/${skin}`, `tips/${skin}`]);
  }, [skin]);

  if (isDisplayError) {
    return <ErrorScreen />;
  }

  return (
    <div className="absolute w-screen h-screen bg-loaderTop">
      {getActiveStage.data?.data && <Sugoroku stage={getActiveStage.data.data} />}
      {getOnboardingCompleted.data && (
        <>
          {getOnboardingCompleted.data?.data.onboardingCompleted && onboardingStep == undefined ? (
            <Loader>
              {getItems.data && (
                <motion.div
                  className="absolute w-full flex flex-col items-center top-[20%]"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  {getItems.data.data.find((x) => !x.confirmed) ? (
                    <>
                      <img className="h-12" src="loader/lets_go.png" alt="let's go" />
                      <p className="fsz-24ptr text-blackBold">{t('loader.proceed')}</p>
                    </>
                  ) : (
                    <img className="h-12" src="loader/loading.png" alt="loading" />
                  )}
                </motion.div>
              )}
            </Loader>
          ) : (
            <>{getItems.data && <Onboarding items={getItems.data.data} />}</>
          )}
        </>
      )}
    </div>
  );
};

export default Top;
