import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';

import HowToPlayPopup from '../onboarding/HowToPlayPopup';

import { useSkin } from './hooks';
import { StageConfig, PieceConfig } from './types';

export type MapProps = {
  confirmed: number;
  remain: number;
  currentPiece: PieceConfig;
  stageConfig: StageConfig;
  open: boolean;
  onClose: () => void;
};

const Map = ({ open, confirmed, remain, stageConfig, currentPiece, onClose }: MapProps) => {
  const skin = useSkin();
  const { t } = useTranslation(['common', `pieces/${skin}`]);
  const [openHowToPlay, setOpenHowToPlay] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({ top: '', left: '' });
  const [goalPosition, setGoalPosition] = useState({ top: '', left: '' });
  const [handPosition, setHandPosition] = useState({ top: '', left: '' });

  const onMapLoaded = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const goalPiece = stageConfig.pieces.find((x) => x.isGoal)!;
    const rate = event.currentTarget.clientWidth / event.currentTarget.naturalWidth;
    setGoalPosition({
      top: `${goalPiece.mapTop * rate}px`,
      left: `${goalPiece.mapLeft * rate}px`,
    });
    setHandPosition({
      top: `${goalPiece.mapTop * rate - 62}px`,
      left: `${goalPiece.mapLeft * rate + 2}px`,
    });
    setCurrentPosition({
      top: `${currentPiece.mapTop * rate}px`,
      left: `${currentPiece.mapLeft * rate}px`,
    });
    console.log(event.currentTarget.width);
  };

  return (
    <>
      <Dialog
        openDialog={open}
        width={'full'}
        nonRound
        noPadding
        borderColor="nonBorder"
        backgroundColor="blueLight"
      >
        <div className="relative flex flex-col h-full justify-between items-center gap-2 px-5 py-4 overflow-y-auto">
          <div className="shadow-map bg-mapDialog w-[17.375rem] rounded-[0.625rem] px-4 py-1">
            <div className="flex flex-row items-center justify-center">
              <div className="max-w-[1.563rem] my-2">
                <img src="./sugoroku_map_flag.svg" className="w-[100%] h-auto" alt="intro1" />
              </div>
              <p className="fsz-20ptr font-bold text-darkBrown">
                {t('sugoroku.map_screen.level', { level: stageConfig.order })}
              </p>
            </div>
            <hr className="border-dialogBorderGray  border-solid" />
            <div className="flex justify-center items-center mt-1">
              <div className="flex items-center bg-blackGray rounded-[1.25rem] w-[4rem] justify-center mr-1 py-1">
                <p className="fsz-14ptr font-bold text-white text-center w-[3rem]">
                  {t('sugoroku.map_screen.goal')}
                </p>
              </div>
              <img
                className="h-8"
                src={`map_goal_text/${skin}/${stageConfig.key}.png`}
                alt="goal"
              />
            </div>
          </div>
          <div className="relative shrink mt-1 min-h-0 ">
            <div className="mx-auto lg:h-96 lg:w-96 sm:w-36 smLarge:w-60 smLarge:h-60 sm:h-36">
              <img
                onLoad={onMapLoaded}
                src={`/goal_map/${skin}/${stageConfig.key}.png`}
                className="rounded-xl border-4 h-full border-paleBlue"
                alt="map"
              />
              <div
                className="absolute flex items-center bg-blackGray rounded-[1.25rem] w-[4rem] justify-center mr-1 py-1"
                style={{
                  translate: '-60% -100%',
                  ...handPosition,
                }}
              >
                <p className="fsz-14ptr font-bold text-white text-center w-[3rem]">
                  {t('sugoroku.map_screen.goal')}
                </p>
              </div>
              <img
                src="./pointer_hand.svg"
                className="absolute w-10"
                alt="pointer"
                style={{
                  translate: '-60% -100%',
                  ...goalPosition,
                }}
              />
              <img
                src="./sugoroku/character_stop.svg"
                className="absolute w-12"
                alt="character"
                style={{
                  translate: '-40% -100%',
                  ...currentPosition,
                }}
              />
            </div>
          </div>
          <div className="mt-6 smLarge:mt-0 flex justify-center items-center">
            <div className="flex flex-col">
              <div className="flex items-center bg-blackGray rounded-[1.25rem] mb-2 w-[8.75rem] justify-center">
                <p className="fsz-16ptr text-white text-center w-[5rem]">
                  {t('sugoroku.map_screen.title_1')}
                </p>
              </div>
              <div className="flex shadow-map bg-mapDialog rounded-[0.625rem] px-3 w-[4.375rem] items-center justify-center">
                <p className="fsz-36ptr font-bold text-center">{confirmed}</p>
              </div>
              <div className="flex items-center bg-blackGray rounded-[1.25rem] mb-2 w-[8.75rem] justify-center mt-5 smLarge:mt-1">
                <p className="fsz-16ptr text-white text-center w-[5rem]">
                  {t('sugoroku.map_screen.title_2')}
                </p>
              </div>
              <div className="flex items-baseline">
                <div className="flex shadow-map bg-mapDialog rounded-[0.625rem] px-3 w-[4.375rem] items-center justify-center">
                  <p className="fsz-36ptr font-bold text-center">{remain}</p>
                </div>
                <p className="fsz-20ptr font-bold ml-2">{t('sugoroku.map_screen.frame')}</p>
              </div>
            </div>
            <div className="flex ml-10 self-end">
              <div className="flex items-center justify-center flex-col relative">
                <div className="absolute -top-[6.5rem] -right-[1.75rem]">
                  <div className="top-0 w-[11.25rem] ">
                    <img
                      src="/onboarding_text_area.svg"
                      className="w-[100%] h-auto"
                      alt="cat-text"
                    ></img>
                  </div>

                  <p className="absolute top-4 whitespace-pre-wrap text-center fsz-18ptr w-[11.25rem] left-0 right-0 m-auto">
                    {t('sugoroku.map_screen.cat_talk')}
                  </p>
                </div>
                <img src="/hug_cat.svg" className="" alt="cat"></img>
              </div>
            </div>
          </div>
          <div className="flex justify-between w-full items-end lg:max-w-[24rem]">
            <Button
              className="self-start"
              text={t('common.close')}
              colorType={'pink'}
              buttonSize={'medium'}
              onClick={() => {
                setOpenHowToPlay(false);
                onClose();
              }}
            />
            <Button
              text={t('sugoroku.map_screen.how_to_play')}
              colorType={'blue'}
              buttonSize={'small'}
              onClick={() => setOpenHowToPlay(true)}
            />
          </div>
        </div>
      </Dialog>
      <HowToPlayPopup open={openHowToPlay} onClose={() => setOpenHowToPlay(false)} />
    </>
  );
};

export default Map;
