// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';

export type CompleteOnboardingDTO = {
  onboardingCompleted: boolean;
};

export const completeOnboarding = ({ onboardingCompleted }: CompleteOnboardingDTO) => {
  return axios.patch(`game/onboarding_completed`, {
    onboardingCompleted: onboardingCompleted,
  });
};

type CompleteOnboardingOptions = {
  config?: MutationConfig<typeof completeOnboarding>;
};

export const useCompleteOnboarding = ({ config }: CompleteOnboardingOptions = {}) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries('onBoardingCompleted');
    },
    ...config,
    mutationFn: completeOnboarding,
  });
};
