// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { motion } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';
import Ribbon from '@/components/Elements/Ribbon';

export type ItemAcquisitionPopupProps = {
  title: string;
  onClose?: () => void;
};

const ItemAcquisitionPopup = ({ title, onClose = () => {} }: ItemAcquisitionPopupProps) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(true);

  return (
    <>
      <Dialog
        openDialog={openDialog}
        borderColor={'blueLight'}
        backgroundColor="dialogBlue"
        onClose={onClose}
        width="middle"
      >
        <motion.div
          className="relative"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            ease: 'easeIn',
            duration: 0.5,
          }}
        >
          <Ribbon type="centerBlue" title={t('item.item_acquition_popup.title')} />
        </motion.div>
        <div className="flex flex-col items-center justify-center">
          <motion.div
            className="bg-[url('/public/tip_bg.svg')] w-[100%] min-h-[269px] bg-no-repeat bg-center flex flex-col items-center"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              ease: 'easeIn',
              duration: 0.5,
            }}
          >
            <p className="fsz-32ptr font-bold mt-8">{title}</p>
            <div className="max-w-[115px] mt-3">
              <img
                src="./item_acquisition_key.svg"
                className="w-[100%] h-auto"
                alt="itemImageDemo"
              />
            </div>
            <p className="fsz-16ptr mt-8 whitespace-pre-wrap text-center">
              {t('item.item_acquition_popup.desc')}
            </p>
          </motion.div>

          <Button
            className="mt-10 mx-[60px]"
            text={t('common.close')}
            colorType={'pink'}
            buttonSize={'large'}
            onClick={() => {
              setOpenDialog(false);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default ItemAcquisitionPopup;
