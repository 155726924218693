// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import Button from '@/components/Elements/Button';
import Dialog from '@/components/Elements/Dialog';
import Ribbon from '@/components/Elements/Ribbon';
import { SugorokuStageConfig } from '@/config';

import { useSkin } from '../sugoroku/hooks';

import { StageItem } from './types';

export type ItemTipsPopupProps = {
  stageItem: StageItem;
  onClose?: () => void;
  open: boolean;
};

const ItemTipsPopup = ({ open, stageItem, onClose }: ItemTipsPopupProps) => {
  const skin = useSkin();
  const { t } = useTranslation(['common', `pieces/${skin}`, `tips/${skin}`]);
  const pieceConfig = SugorokuStageConfig()
    .flatMap((x) => x.pieces)
    .find((x) => x.stageItemId == stageItem.id);

  return (
    <>
      <Dialog
        width="middle"
        openDialog={open}
        borderColor={'blueLight'}
        backgroundColor="dialogBlue"
      >
        <Ribbon
          type="centerBlue"
          title={t('item.item_tips_popup.title')}
          bookmarkText={() =>
            pieceConfig?.isSpecial
              ? t('item.item_tips_popup.bookmark.special', { index: pieceConfig?.index })
              : t('item.item_tips_popup.bookmark.normal', { index: pieceConfig?.index })
          }
        />
        <div className="flex flex-col items-center overflow-y-auto">
          <div className="bg-[url('/public/tip_bg.svg')] w-[100%] min-h-[269px] bg-no-repeat bg-center flex flex-col items-center">
            <div className="flex justify-center items-center bg-blackGray rounded-[1.25rem] px-3 my-2">
              <p className="fsz-16ptr text-white font-bold">
                {t('item.item_tips_popup.city', {
                  city: t(`${pieceConfig?.index}.city`, { ns: `pieces/${skin}` }),
                })}
              </p>
            </div>
            <p className="fsz-24ptr font-bold text-center">{stageItem.title}</p>
            <p className="fsz-16ptr text-cyanBlue font-bold">
              {t(`${pieceConfig?.tipsIndex}.name`, { ns: `tips/${skin}` })}
            </p>
            <motion.div
              className="max-w-[200px] mt-3"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, scale: [10, 1, 1.5, 1] }}
              transition={{
                ease: 'easeIn',
                duration: 1,
                // 1 is delay transition
                delay: 1,
              }}
            >
              <img src={stageItem.imageUrl} className="w-[100%] h-auto" alt="itemImageDemo" />
            </motion.div>
          </div>
          <p className="fsz-16ptr mx-[0.625rem] md:mx-[2.813rem] mt-3">{stageItem.description}</p>
          <Button
            className="mt-4 mb-1"
            text={t('common.close')}
            colorType={'pink'}
            buttonSize={'large'}
            onClick={onClose}
          />
        </div>
      </Dialog>
    </>
  );
};
export default ItemTipsPopup;
