// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { Response } from '@/types';

import { Item } from '../types';

import { patchItemConfirm } from './patchItemConfirm';

export type CreateOnboardingItemDTO = {
  stageItemId: number;
  skipItemId?: number;
  achievementDetail?: string;
};

export const CreateOnboardingItem = ({
  stageItemId,
  skipItemId,
  achievementDetail,
}: CreateOnboardingItemDTO): Promise<Response<Item>> => {
  return axios
    .post(`game/items/onboarding`, {
      stageItemId: stageItemId,
      skipItemId: skipItemId,
      achievementDetail: achievementDetail,
    })
    .then((res) => patchItemConfirm({ itemId: res.data.id }));
};

type CreateOnboardingItemOptions = {
  stageId: number;
  config?: MutationConfig<typeof CreateOnboardingItem>;
};

export const useCreateOnboardingItem = ({ stageId, config }: CreateOnboardingItemOptions) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['items', stageId]);
    },
    ...config,
    mutationFn: CreateOnboardingItem,
  });
};
