// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useMutation } from 'react-query';

import { axios } from '@/lib/axios';
import { MutationConfig, queryClient } from '@/lib/react-query';
import { Response } from '@/types';

import { UserNotification } from '../types';

export type PatchNotificationConfirmDTO = {
  notificationId: number;
};

export const patchNotificationConfirm = ({
  notificationId,
}: PatchNotificationConfirmDTO): Promise<Response<UserNotification>> => {
  return axios.patch(`game/user_notifications/${notificationId}/confirmed`);
};

type PatchNotificationConfirmOptions = {
  config?: MutationConfig<typeof patchNotificationConfirm>;
};

export const usePatchNotificationConfirmed = ({ config }: PatchNotificationConfirmOptions = {}) => {
  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['userNotifications']);
    },
    ...config,
    mutationFn: patchNotificationConfirm,
  });
};
