// gkc_hash_code : 01GKRBSM6TJAQ3DYFRPWJ69CPX

import { useNotificationStore } from '@/stores/notifications';

import Toast from '../Toast';

const Notifications = () => {
  const { notifications, dismissNotification } = useNotificationStore();

  return (
    <div className="z-50 pointer-events-none flex flex-col justify-end fixed inset-0 space-y-2 content-bottom px-4 py-6">
      {notifications.map((notification) => (
        <Toast
          key={notification.id}
          text={notification.message}
          colorType={notification.type == 'error' ? 'error' : 'primary'}
          onDismiss={() => {
            dismissNotification(notification.id);
          }}
        />
      ))}
    </div>
  );
};

export default Notifications;
